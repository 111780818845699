


import $ from "jquery";





export class KnowledgeHubConfig
{
    constructor(baseUrl, dataVersionStr)
    {
        this._baseUrl = baseUrl;
        this._defaultDataVersion = dataVersionStr;

        // Extract URL Params
        this._urlQueryParams = this.getUrlParams();
        console.log(`URL Query Params: ${JSON.stringify(this._urlQueryParams)}`);
    }

    get baseUrl()
    {
        return this._baseUrl;
    }

    get defaultDataVersion()
    {
        return this._defaultDataVersion;
    }

    get urlQueryParams()
    {
        return this._urlQueryParams;
    }

    resourceUrl(rsrcPath)
    {
        return this._baseUrl + "resources/" + rsrcPath;
    }

    versionedDataUrl(relativePath)
    {
        if (relativePath.startsWith("/"))
        {
            relativePath = relativePath.substring(1);
        }

        return `${this.baseUrl}data/${this.defaultDataVersion}/${relativePath}`;
    }

    async promiseExtendedConfig()
    {
        if (typeof this._extendedConfig === "undefined" || this._extendedconfig == null)
        {
            return $.ajax({
                url: this._baseUrl + "data/stable/config/config.json",
                type: "GET"
            }).then(cfg => {
                this._extendedConfig = cfg;
                console.log(`Loaded configuration ${JSON.stringify(this._extendedConfig)}`);
                return cfg;
            });
        }
        else
        {
            return Promise.resolve(this._extendedConfig);
        }
    }

    loadExtendedConfig(callback)
    {
        if (typeof this._extendedConfig === "undefined")
        {
            $.ajax({
                url: this._baseUrl + "data/stable/config/config.json",
                type: "GET"
            }).done(function(data, textStatus, jqXHR) {
                this._extendedConfig = data;
                console.log(`Loaded configuration ${JSON.stringify(this._extendedConfig)}`);
                callback(this._extendedConfig);
            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log("Failed to load extended config");
            });
        }
        else
        {
            callback(this._extendedConfig);
        }
    }

    getUrlParams () {
        const url = window.location.href.split('#')[0]; // Discard fragment identifier.

        const urlParams = {};

        let queryString = url.split('?')[1];

        if (queryString)
        {
            const keyValuePairs = queryString.split('&');
            for (let i = 0; i < keyValuePairs.length; i++)
            {
                const keyValuePair = keyValuePairs[i].split('=');
                const paramName = keyValuePair[0];
                const paramValue = keyValuePair[1] || '';

                if (paramName !== "")
                {
                    urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
                }
            }
        }

        return urlParams;
    }
}