

import $ from 'jquery';

console.log("scroller.js entered...");

export class Scroller {

    /**
     * Constructs a new Scroller in a specified DIV on the page.
     *
     * @param {string} scrollerId - The ID of the main DIV element that contains the Scroller
     * @param {Object} configParams - Map of initial parameter values to use for configuring the Scroller
     * @param {blockDataLoaderFunc} configParams.blockDataLoader -
     *             Function that initiates asynchronous loading of that data for a specified block.  Must be provided if
     *             the Scroller loads data dynamically
     * @param {populateColumnFunc} configParams.populateColumn -
     *             Function that populates a column within the slider given the block data, the index of the column to populate,
     *             and the div to inject the content into.  Must be provided if the Scroller loads data dynamically
     * @param {number} [configParams.blocksPreloaded=1] - The number of blocks worth of data that are included in the Scroller's static HTML
     */
    constructor(scrollerId, configParams) {
        this.scrollerId = scrollerId;

        this.populateConfig(configParams || {});

        this.init();
    }

    get totalItems() { return this._totalItems; }
    get itemsPerColumn() { return this._itemsPerColumn; }
    get itemsPerBlock() { return this._itemsPerBlock; }
    get totalBlocks() { return this._totalBlocks; }
    get totalColumns() { return this._totalColumns;  }
    get columnsPerBlock() { return this._columnsPerBlock; }
    get userData() { return this._userData; }
    get currentMode() { return this._currentMode; }

    /**
     *
     * @private
     */
    populateConfig(configParams)
    {
        // Block loader - function(blockNum, successCallback(data), errorCallback(errInfo) )

        /**
         * Callback function used by the Scroller to initiate loading data
         * @type {blockDataLoaderFunc}
         */
        this.blockDataLoader = configParams.blockDataLoader;

        /**
         * Callback function used by the Scoller to populate the HTML for a column using data returned by the
         * blockDataLoader
         * @type {populateColumnFunc}
         */
        this.populateColumn = configParams.populateColumn;


        // Setup Elems
        this.$scroller = $("#" + this.scrollerId);
        this.$wrapper = $("#" + this.scrollerId + " .wrapper");
        this.$items = $("#" + this.scrollerId + " .items");

        // Item config
        this.blocksPreloaded = this.initConfigParamNum(configParams, "blocksPreloaded", "blocks-preloaded", 1);
        this._itemsPerColumn = this.initConfigParamNum(configParams, "itemsPerColumn", "items-per-column");     // config.itemsPerColumn;
        this._itemsPerBlock = this.initConfigParamNum(configParams, "itemsPerBlock", "items-per-block");        // config.itemsPerBlock;

        this.updateItemsParams(this.initConfigParamNum(configParams, "totalItems", "total-items"));

        // Initial state values
        this.scrollInProgress = false;
        this.modeChangeInProgress = false;

        // User data, if present
        this._userData = configParams.userData || {};
    }

    updateItemsParams(newTotalItems)
    {
        this._totalItems = newTotalItems;

        this._totalBlocks = Math.ceil(this.totalItems / this.itemsPerBlock);
        this._totalColumns = Math.ceil(this.totalItems / this.itemsPerColumn);
        this._columnsPerBlock = this.itemsPerBlock / this.itemsPerColumn;

        this.lastLoadedColumn = 0;

        this.columnInfos = { };
    }

    initConfigParam(configParams, name, dataName, defaultVal)
    {
        // Look in "data-{dataName}" param first
        const attrVal = this.$scroller.attr("data-" + dataName);
        if (typeof attrVal != "undefined")
        {
            return attrVal;
        }

        // Look in configParams, if avalable
        if (configParams)
        {
            const configVal = configParams[name];
            if (typeof configVal != "undefined")
            {
                return configVal;
            }
        }

        // Else default val, if present
        if (typeof defaultVal != "undefined")
        {
            return defaultVal;
        }
        else
        {
            throw "Missing config value: " + name;
        }
    }

    initConfigParamNum(configParams, name, dataName, defaultVal)
    {
        return parseInt(this.initConfigParam(configParams, name, dataName, defaultVal));
    }


    populateBlock(blockNumber, loadData)
    {
        const firstColumnNumInBlock = blockNumber * this.columnsPerBlock;

        // Only populate if the block isn't already populated
        if (!this.isBlockPopulated(blockNumber))
        {
            console.log(`Populating DIVs for block ${blockNumber} @ column ${firstColumnNumInBlock}`);


            //
            // Add columns and column infos
            //
            let prevColDiv = (firstColumnNumInBlock > 0 ? this.columnInfos[this.findFirstAvailableColumnBefore(firstColumnNumInBlock)].columnDiv : null);
            let firstNewColumnDiv = null;

            for (let i = firstColumnNumInBlock, firstItemInColumn = blockNumber * this.itemsPerBlock;
                 i < firstColumnNumInBlock + this.columnsPerBlock && i < this.totalColumns;
                 ++i, firstItemInColumn += this.itemsPerColumn)
            {
                // If we've hit the end of all items, don't add the column
                if (firstItemInColumn > this.totalItems)
                {
                    break;
                }

                // Add empty column
                const newCol = $("<div/>", { class: "item item-loading", "data-col": i }).text(` `);
                if (firstNewColumnDiv == null) {
                    firstNewColumnDiv = newCol;
                }

                if (prevColDiv != null) {
                    $(prevColDiv).after( newCol );
                } else {
                    this.$items.append( newCol );
                }


                //const finalItemsWidth = this.$items.width();
                //console.log(`Items width before: ${initialItemsWidth}, after: ${finalItemsWidth}`);

                prevColDiv = newCol;

                // After adding a new column, we may need to adjust the items div to maintain the original scroll position
                const newColXPos = $(newCol).position().left;
                const newColWidth = $(newCol).width();

                // Increase size of items element
                //this.$items.width(initialItemsWidth + newColWidth);
                //console.log(`Items width after update: ${this.$items.width()}`);

                // Scroll if necessary
                if (newColXPos < 0)
                {
                    console.log(`Need to shift items ${newColWidth} pixels to account for newly added item`);
                    const currentItemsPos = this.$items.position();
                    this.$items.position({ top: currentItemsPos.top, left: currentItemsPos.left - newColWidth });
                    //const currentItemOffset = this.$items.offset();
                    //this.$items.offset({ top: currentItemOffset.top, left: currentItemOffset.left - newColWidth });
                    console.log(`New items position: ${this.$items.position().left}`);
                }

                // Add column info
                this.addColumnInfo(i, newCol, -1);
            }
        }


        // Initiate loading the new block's data, to be injected asynchronously
        if (loadData && !this.isBlockLoadingOrLoaded(blockNumber))
        {
            this.initiateBlockDataLoad(blockNumber);
        }
    }

    processLoadedBlockData(data, blockNumber)
    {
        console.log(`Processing data loaded for block ${blockNumber}`);

        // If a mode change is in progress, clear the items before processing the data
        if (this.modeChangeInProgress)
        {
            // Clear items & remove loading status
            this.$items.empty();
            this.$items.removeClass("loading");

            // Populate item divs for the new block
            this.populateBlock(0, false);

            // Clear flag
            this.modeChangeInProgress = false;

            // Re-enable mode select
            // Update the mode select button & disable until mode change is complete
            this.$modeSelect.find("button").each(function() {
                $(this).removeAttr("disabled");
            });
        }

        // Populate each column belonging to the block & set status to LOADED
        for (let colNum = blockNumber * this.columnsPerBlock;
             colNum < ((blockNumber + 1) * this.columnsPerBlock)
             && colNum < this.totalColumns;
             ++colNum)
        {
            const colInfo = this.columnInfos[colNum];
            if (typeof colInfo === "undefined")
            {
                // We are out of columns, since the block wasn't full of data
                break;
            }

            const colDiv = colInfo.columnDiv;

            // Clear the LOADING content from the DIV
            $(colDiv).empty();

            // Populate the DIV... populateColumn = function(columnDiv, columnNumber, blockNumber, blockData)
            this.populateColumn(colDiv, colNum, blockNumber, data, this);


            // Mark column as loaded
            $(colDiv).removeClass("item-loading");
            colInfo.loaded = 1;

            // Update controls, as if we had scrolled since the NEXT button may now be enabled, for example
            this.updateControlsAfterScroll();
        }
    }

    /**
     *
     * @param blockNumber
     * @throws Error if the block is currently being loaded, or has already been loaded, or is invalid
     */
    initiateBlockDataLoad(blockNumber)
    {
        // This will throw an error if the block number is invalid
        if (this.isBlockLoadingOrLoaded(blockNumber))
        {
            throw "Cannot load block that is already loading " + blockNumber;
        }

        console.log(`Initiating block data load for block ${blockNumber}`);

        const self = this;

        const successCallback = function(data) {
            console.log(`Completed load of data for block ${blockNumber}`);
            self.processLoadedBlockData(data, blockNumber);

            /*
            // Populate each column belonging to the block & set status to LOADED
            for (let colNum = blockNumber * self.columnsPerBlock;
                 colNum < ((blockNumber + 1) * self.columnsPerBlock)
                 && colNum < self.totalColumns;
                 ++colNum)
            {
                const colInfo = self.columnInfos[colNum];
                if (typeof colInfo === "undefined")
                {
                    // We are out of columns, since the block wasn't full of data
                    break;
                }

                const colDiv = colInfo.columnDiv;

                // Clear the LOADING content from the DIV
                $(colDiv).empty();

                // Populate the DIV... populateColumn = function(columnDiv, columnNumber, blockNumber, blockData)
                self.populateColumn(colDiv, colNum, blockNumber, data, self);

                // Mark column as loaded
                $(colDiv).removeClass("item-loading");
                colInfo.loaded = 1;

                // Update controls, as if we had scrolled since the NEXT button may now be enabled, for example
                self.updateControlsAfterScroll();
            }
            */
        };

        const errorCallback = function(errInfo) {
            console.log(`Failed load of data for block ${blockNumber}`);

            // Set block's loading status to NOT_LOADING
        };

        // Block loader - function(blockNum, successCallback(data), errorCallback(errInfo) )
        this.blockDataLoader(blockNumber, successCallback, errorCallback, this);

        // Mark all columns in the block as LOADING
        for (let colNum = blockNumber * this.columnsPerBlock; colNum < (blockNumber + 1) * this.columnsPerBlock; ++colNum)
        {
            // Note that the column might not exist if we're on the last block and it isn't full
            if (this.columnInfos[colNum])
            {
                this.columnInfos[colNum].loaded = 0;
            }
        }

    }



    handlePagerButtonClick(btn) {
        const scrollToItemNum = parseInt($(btn).attr("data-scroll-to"));
        console.log("Pager button clicked: " + scrollToItemNum);
        this.scrollToItem(scrollToItemNum);
        $(btn).blur();
    }

    handleScroll() {

        const firstVisColNum = this.findFirstPartiallyVisibleColumn();

        // Enable/Disable PREV and NEXT buttons as appropriate
        this.updateControlsAfterScroll();

        // If we're not in the middle of a scrollTo, check for data loading
        if (!this.scrollInProgress)
        {
            console.log("Checking to see if we need to load data...");

            // Look forward 7 columns
            if (firstVisColNum + 7 < this.totalColumns)
            {
                const followingBlockNum = Math.floor((firstVisColNum + 7) / this.columnsPerBlock);
                if (!this.isBlockLoadingOrLoaded(followingBlockNum))
                {
                    console.log(`Forward loading data for upcoming block ${followingBlockNum} due to scroll`);
                    this.populateBlock(followingBlockNum, true);
                }
            }

            // Look backwards 3 columns
            if (firstVisColNum - 3 > this.columnsPerBlock)
            {
                const prevBlockNum = Math.floor((firstVisColNum - 3) / this.columnsPerBlock);
                if (!this.isBlockLoadingOrLoaded(prevBlockNum))
                {
                    console.log(`Forward loading data for preceding block ${prevBlockNum} due to scroll`);
                    this.populateBlock(prevBlockNum, true);
                }
            }
        }
    }



    /**
     * Updates PAGER BUTTONS and PREV/NEXT buttons anytime the contents have been scrolled
     */
    updateControlsAfterScroll()
    {
        const wrapperWidth = this.$wrapper.width();
        const itemsXPos = this.$items.position().left;

        // PREV BUTTON
        if (itemsXPos < 0) {
            this.$prevBtn.removeClass("disabled");
        } else {
            this.$prevBtn.addClass("disabled");
        }

        // NEXT BUTTON
        const lastColDiv = this.columnInfos[this.lastLoadedColumn].columnDiv;
        const lastColXPos = $(lastColDiv).position().left;
        const lastColRight = lastColXPos + $(lastColDiv).width();

        if (lastColRight > wrapperWidth) {
            this.$nextBtn.removeClass("disabled");
        } else {
            this.$nextBtn.addClass("disabled");
        }

        // PAGER BUTTONS
        const pagerButtonSpans = this.$scroller.find(".pager-buttons");
        for (let spanNum = 0; spanNum < pagerButtonSpans.length; ++spanNum)
        {
            const pagerButtonSpan = pagerButtonSpans[spanNum];
            if ($(pagerButtonSpan).is(":visible"))
            {
                const btns = $(pagerButtonSpan).find("button");
                for (let btnNum = 0; btnNum < btns.length; ++btnNum)
                {
                    const btn = btns[btnNum];
                    const scrollToVal = $(btn).attr("data-scroll-to");
                    if (typeof scrollToVal === "undefined" || scrollToVal === false) {
                        continue;
                    }
                    const firstItem = parseInt($(btn).attr("data-scroll-to"));
                    const lastItem = this.findLastItemForButton(btns, btnNum, this.totalItems); // (btnNum + 1 < btns.length ?  parseInt($(btns[btnNum+1]).attr("data-scroll-to")) - 1 : this.totalItems);
                    const firstColumn = Math.floor(firstItem / this.itemsPerColumn);
                    const lastColumn = Math.floor(lastItem / this.itemsPerColumn);

                    let hasVisibleCol = false;

                    for (let colNum = firstColumn; colNum <= lastColumn; ++colNum)
                    {
                        const colInfo = this.columnInfos[colNum];
                        if (colInfo)
                        {
                            const colDiv = colInfo.columnDiv;
                            const colDivXPosition = $(colDiv).position().left;
                            const colDivWidth = $(colDiv).width();
                            const colRight = colDivXPosition + colDivWidth;

                            // If partially or wholly visible, button should be highlighted
                            if ((colDivXPosition >=0 && colDivXPosition <= wrapperWidth - 30) || (colRight >= 0 && colRight <= wrapperWidth - 30) || (colDivXPosition < 0 && colRight > wrapperWidth))
                            {
                                hasVisibleCol = true;
                                break;
                            }
                        }
                    }

                    if (hasVisibleCol)
                    {
                        $(btn).removeClass("btn-outline-dark").addClass("btn-secondary");
                    }
                    else
                    {
                        $(btn).removeClass("btn-secondary").addClass("btn-outline-dark");
                    }
                }
            }
        }

    }

    findLastItemForButton(buttonsArray, buttonIndex, defaultValue)
    {
        for (let i = buttonIndex + 1; i < buttonsArray.length; ++i)
        {
            const btn = buttonsArray[i];
            const btnScrollTo = $(btn).attr("data-scroll-to");
            if (!(typeof btnScrollTo === "undefined" || btnScrollTo === false))
            {
                return parseInt(btnScrollTo) - 1;
            }
        }

        // Didn't find another button with a scroll to, return the default value
        return defaultValue;
    }

    addColumnInfo(columnNumber, columnDiv, isLoaded) {

        const block = Math.floor(columnNumber / this.columnsPerBlock);
        this.columnInfos[columnNumber] = { columnNumber, columnDiv, block, loaded: isLoaded };

        // Update lastLoadedColumn, if applicable
        if (columnNumber > this.lastLoadedColumn)
        {
            this.lastLoadedColumn = columnNumber;
        }
    }




    scrollToItem(itemNum) {
        // Determine the column number that contains the item
        const colNum = Math.floor(itemNum / this.itemsPerColumn);

        console.log(`Scrolling to item ${itemNum} in column ${colNum}...`);

        // If the column isn't already in the DOM, we need to populate it before scrolling to it
        if (typeof this.columnInfos[colNum] === 'undefined')
        {
            // Determine which block we need to load
            const blockNum = Math.floor(colNum / this.columnsPerBlock);
            console.log(`Loading block number ${blockNum} so we can scroll to it`);

            // Populate without loading all unpopulated block before (blockNum -1)
            for (let i = 0; i < blockNum - 1; ++i)
            {
                if (!this.isBlockPopulated(i))
                {
                    this.populateBlock(i, false);
                }
            }

            // Populate the block we're scrolling to
            this.populateBlock(blockNum, true);

            // If the block BEFORE the one we're scrolling to isn't populated, populate it AND load it
            if (!this.isBlockPopulated(blockNum - 1))
            {
                console.log(`Loading preceding block number ${blockNum - 1} as well`);
                this.populateBlock(blockNum - 1, true);
            }
        }

        // Scroll to the target column, which should now exist in the DOM
        this.scrollToExistingColumn(colNum);
    }

    scrollToExistingColumn(colNum)
    {
        if (typeof this.columnInfos[colNum] === 'undefined')
        {
            console.error("Cannot scroll to column "  + colNum);
        }
        else
        {
            console.log(`Scrolling to existing column ${colNum}`);

            console.info(`DEBUG: Items position: ${this.$items.position().left}, width: ${this.$items.width()}`);

            // disable sentries, we will update after scroll finishes
            //this.clearSentries();

            this.scrollInProgress = true;

            // Note that the scrollTo will trigger a scroll event that gets passed to the handleScroll method, which will update the controls
            const self = this;
            this.$wrapper.scrollTo($(this.columnInfos[colNum].columnDiv), 200, { onAfter: function(target, settings) {
                console.log("Scroll finished!!");
                self.scrollInProgress = false;
            }});
        }
    }

    scrollNext(btn) {
        if (!$(btn).hasClass("disabled"))
        {
            console.log("SCROLL NEXT");
            const firstVisibleColNum = this.findFirstPartiallyVisibleColumn();
            if (firstVisibleColNum >= 0)
            {
                const nextCol = this.findFirstAvailableColumnAfter(firstVisibleColNum);
                this.scrollToExistingColumn(nextCol);
            }
        }
    }

    scrollPrev(btn) {
        if (!$(btn).hasClass("disabled"))
        {
            console.log("SCROLL PREV");
            const firstVisibleColNum = this.findFirstPartiallyVisibleColumn();
            if (firstVisibleColNum >= 0)
            {
                const prevCol = this.findFirstAvailableColumnBefore(firstVisibleColNum);
                this.scrollToExistingColumn(prevCol);
            }
        }
    }

    findFirstAvailableColumnBefore(baseCol)
    {
        const baseColNum = parseInt(baseCol);
        let firstAvailable = -1;

        for (let col in this.columnInfos)
        {
            const colNum = parseInt(col);
            if (colNum < baseColNum && (firstAvailable < 0 || colNum > firstAvailable))
            {
                firstAvailable = colNum;
            }
        }

        return firstAvailable >= 0 ? firstAvailable : baseColNum;
    }

    findFirstAvailableColumnAfter(baseCol)
    {
        const baseColNum = parseInt(baseCol);
        let firstAvailable = -1;

        for (let col in this.columnInfos)
        {
            const colNum = parseInt(col);
            if (colNum > baseColNum && (firstAvailable < 0  || colNum < firstAvailable))
            {
                firstAvailable = colNum;
            }
        }

        return firstAvailable >= 0 ? firstAvailable : baseColNum;
    }

    /**
     *
     * @returns {Number} The column number of the first column that is at least partially visible
     */
    findFirstPartiallyVisibleColumn()
    {
        const itemXPos = this.$items.position().left;

        for (let col in this.columnInfos)
        {
            const colDiv = this.columnInfos[col].columnDiv;
            const colXPos = $(colDiv).position().left;
            const colRelativeXPos = colXPos + itemXPos;
            const colWidth = $(colDiv).width();

            if (colXPos <= 10 && colXPos+ colWidth > 5)
            {
                //console.log(`First visible column: ${col}  (col-rel-pos: ${colRelativeXPos}, item-x: ${itemXPos}`);
                return parseInt(col);
            }
        }

        // Oooops
        console.log(`  Item x: ${itemXPos}`);
        for (let col in this.columnInfos)
        {
            const colDiv = this.columnInfos[col].columnDiv;
            console.log(`  Column ${$(colDiv).attr('id')}/${$(colDiv).attr("data-col")}: Position ${$(colDiv).position().left}`);
        }
        throw "Couldn't find partially visible column";
    }


    /**
     * Checks to see whether the specified block number is being loaded or has been loaded
     *
     * @param blockNum
     * @returns {boolean} True if the block is loading or has been loaded,
     *   false if the block has not been populated or loading has not been started
     */
    isBlockLoadingOrLoaded(blockNum)
    {
        if (blockNum < 0 || blockNum > this.totalBlocks)
        {
            throw "Invalid block number: " + blockNum;
        }

        // Look at the first column in the block
        const colInfo = this.columnInfos[blockNum * this.columnsPerBlock];

        if (typeof colInfo === "undefined")
        {
            return false;
        }
        else
        {
            return colInfo.loaded >= 0;
        }
    }

    isBlockLoaded(blockNum)
    {
        // Look at the first column in the block
        const colInfo = this.columnInfos[blockNum * this.columnsPerBlock];

        if (typeof colInfo === "undefined")
        {
            return false;
        }
        else
        {
            return colInfo.loaded === 1;
        }
    }

    isBlockPopulated(blockNum)
    {
        return (typeof this.columnInfos[blockNum * this.columnsPerBlock] != "undefined");
    }

    init() {
        const self = this;

        console.log("Initializing scroller " + this.scrollerId);

        // Add PREV / NEXT scroll buttons
        this.$prevBtn = $("<div/>", { class: "prev-btn disabled" })
                          .append( $("<i/>", { class: "fa fa-2x fa-chevron-circle-left" } ).text("") )
                          .click(function() { self.scrollPrev(this); });
        this.$wrapper.before(this.$prevBtn);

        this.$nextBtn = $("<div/>", { class: "next-btn" })
                          .append( $("<i/>", { class: "fa fa-2x fa-chevron-circle-right" } ).text("") )
                          .click(function() { self.scrollNext(this); });
        this.$wrapper.after(this.$nextBtn);

        // Initialize MODE selector, if there is one
        const $modeSel = this.$scroller.find(".scroller-mode-select").each(function() {

            self.$modeSelect = $(this);

            // Set current mode
            self.$modeSelect.find("button").each(function() {
                self._currentMode = self.getModeFromElem(this);
                console.log(`Initial mode: ${self.currentMode}`);
            });


            // Add click handler to each mode
            self.$modeSelect.find("a").each(function() {
                $(this).click(function() {
                    self.handleModeSelect(this);
                });
            });
        });


        // Attach CLICK handler for all pager buttons (note: button may be disabled, in which case we should ignore it in the handler)
        this.$scroller.find(".pager-buttons button").each(function() {
            $(this).click(function() {
                self.handlePagerButtonClick(this);
                return false;
            });
        });

        // Attach SCROLL event handler
        this.$wrapper.scroll(function() {
            self.handleScroll();
        });


        if (this.blocksPreloaded > 0)
        {
            // Populate initial column infos for preloaded data
            const divs = this.$items.children("div");
            for (let blockNum = 0; blockNum < this.blocksPreloaded; ++blockNum)
            {
                for (let colPosInBlock = 0; colPosInBlock < this.columnsPerBlock && (blockNum * this.itemsPerBlock + colPosInBlock * this.itemsPerColumn < this.totalItems); ++colPosInBlock)
                {
                    const colIndex = (blockNum * this.columnsPerBlock) + colPosInBlock;
                    const colDiv = divs[colIndex];
                    if (typeof colDiv === "undefined")
                    {
                        throw `Missing preloaded column ${i} (${this.blocksPreloaded} blocks preloaded)`;
                    }
                    else
                    {
                        this.addColumnInfo(colIndex, colDiv, 1);
                    }
                }
            }
        }
        else
        {
            // Nothing loaded, so load block 0
            this.populateBlock(0, true);
        }


        // Do an initial update of the controls
        this.updateControlsAfterScroll();
    }

    handleModeSelect(modeLinkElem)
    {
        const selectedMode = this.getModeFromElem(modeLinkElem);
        if (this.currentMode === selectedMode)
        {
            console.log(`Ignoring change to current mode: ${this.currentMode}`);
            return;
        }

        this.beginModeChange(selectedMode, $(modeLinkElem).text(), modeLinkElem);
    }

    getModeFromElem(elem) {
        let dataMode = $(elem).attr("data-mode");
        if (typeof dataMode === "undefined" || dataMode == null || dataMode === "")
        {
            dataMode = $(elem).text().trim().toLowerCase().replace(/\s/g, "_");
        }
        else
        {
            dataMode = dataMode.trim();
        }
        return dataMode;
    }

    beginModeChange(newMode, newModeDisplayName, modeLinkElem) {
        console.log(`Changing mode to: ${newMode}`);

        // Update the mode select button & disable until mode change is complete
        this.$modeSelect.find("button").each(function() {
            $(this).text(newModeDisplayName).attr("disabled", "disabled");
        });

        // Hide current pager buttons & show new ones
        this.$scroller.find(`.pager-buttons[data-mode='${this.currentMode}']`).hide();
        this.$scroller.find(`.pager-buttons[data-mode='${newMode}']`).show();

        // Update mode
        this._currentMode = newMode;

        // Set flag so we know we need to re-enable the mode selector after data is loaded
        this.modeChangeInProgress = true;

        // hide the data
        this.$items.find(".item").css("visibility", "hidden");
        this.$items.addClass("loading");

        // Clear/reset data structures
        this.updateItemsParams(parseInt($(modeLinkElem).attr("data-total-items")));

        // Initiate load
        this.initiateBlockDataLoad(0);
    }

}


/**
 * Callback function invoked by the Scroller to populate the HTML for one column within the Scroller using data that
 * was dynamically loaded from an external source.
 *
 * @typedef {function} populateColumnFunc
 * @param {Object} columnDiv - This is the DIV (wrapped in a jQuery object) that the column's content should be added to
 * @param {number} columnNumber - The column number to be populated.  This is zero-based and is the column's position within
 *                                the scroller as a whole, not within the block only.
 * @param {number} blockNumber - The block number that the column belongs to
 * @param {Object} blockData - The data for the block that contains the column, as returned by the blockLoader function
 */

/**
 * Callback function invoked by the Scroller to dynamically load a block's worth of data from an external source.
 *
 * @typedef {function} blockDataLoaderFunc
 * @param {number} blockNum
 * @param {function} blockDataLoaderSuccessCallback
 * @param {function} blockDataLoaderErrorCallback
 */

