

import $ from "jquery";

import { Config } from "./infohub-setup";
import { GuidedTour } from "./hub-guided-tour";
import { ModalDialog } from "./component-dialog";


class KnowledgeHubUtils
{
    constructor()
    {
    }

    event(eventType, params)
    {
        try
        {
            let url = Config.baseUrl + "data/stable/event/info.json?t=" + encodeURIComponent(eventType);
            if (typeof params !== "undefined" && params.length > 0)
            {
                for (let i = 0; i < params.length; ++i)
                {
                    url += `&p${i}=${encodeURIComponent(params[i])}`;
                }
            }

            $.ajax({
                url: url,
                method: "GET",
                cache: false
            }).done(function(data, textStatus, jqXHR) {
                console.log("Successfully sent event");
            }).fail(function (jqXHR, textStatus, errorThrown) {
                console.log("Error writing event");
            });
        }
        catch (err)
        {
            console.log("ERROR: " + err);
        }
    }

    getUrlParams()
    {
        const url = window.location.href.split('#')[0]; // Discard fragment identifier.

        const urlParams = {};

        let queryString = url.split('?')[1];

        if (queryString)
        {
            const keyValuePairs = queryString.split('&');
            for (let i = 0; i < keyValuePairs.length; i++)
            {
                const keyValuePair = keyValuePairs[i].split('=');
                const paramName = keyValuePair[0];
                const paramValue = keyValuePair[1] || '';

                if (paramName !== "")
                {
                    urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
                }
            }
        }

        return urlParams;
    }

    showWelcomeMessage()
    {
        const dialogBody = `<h3>Welcome to the Air University Press Knowledge Hub!</h3>
<p>The Air University Press (AUP) Knowledge Hub is a pilot program providing a new research capability,
including generating citations in AU style, for student projects around the entire AU campus.
The Knowledge Hub provides a wiki-like interface for AU students to perform faster, more advanced
research of AUP publications, to include our journals and books. It analyzes AUP digital publications
to cross-link key concepts, people, and events. If you, our readers, find the pilot to be useful,
then we will consider pursuing the Knowledge Hub capabilities. However, we need your help testing
and providing your feedback over the next three months for us to determine if it's of value to you
and worth pursuing.</p>
<p>To learn more about the Knowledge Hub, you can take a guided tour that will walk you through the features of the Knowledge Hub by clicking on the button below.</p>
<p>If you have any questions, comments, or suggestions while using the Knowledge Hub, click on the Comments link in the menu bar.</p>
`;

        const modal = new ModalDialog("khubWelcomeMessageDialog", {
            title: "Welcome!",
            body: dialogBody,
            submitButtonLabel: "Begin Guided Tour",
            submitHandler: function() {
                console.log("Beginning guided tour");
                new GuidedTour().beginTour();
            }
        });

        modal.show();
    }
}


export const knowledgeHubUtils = new KnowledgeHubUtils();