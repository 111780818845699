

import $ from "jquery";

import { FavoriteButton } from "../component-favorite-button";
import { FavoritesManager } from "../hub-favorites-manager";
import { Scroller } from "../scroller";


export class TopicHompage
{
    /**
     * config: topicIid
     * @param config
     */
    constructor(config)
    {
        this.favsMgr = new FavoritesManager();

        this.populateConfig(config);

        this.init();
    }

    populateConfig(config)
    {
        this.config = config;
        this.title = $("#topicNameSpan").text();
        this.thumbUrl = "";
        this.topicPgUrl = window.location.href;
    }

    handleToggleFavorite(newState, favBtnObj)
    {
        const topicIid = this.config.topicIid;

        console.log(`Toggling favorite for topic ${topicIid} to ${newState}`);

        if (newState)
        {
            this.favsMgr.addToFavorites("topics", topicIid, {
                "topicIid": topicIid,
                "title": this.config.title,
                "thumbUrl": this.config.thumbUrl,
                "topicPgUrl": this.config.topicPgUrl,
                "totalRefs": this.config.totalRefs
            });
        }
        else
        {
            this.favsMgr.removeFromFavorites("topics", topicIid)
        }
    }

    init()
    {
        const self = this;
        console.log("Initializing TopicHomepage...");

        const isFavorite = this.favsMgr.isFavorite("topics", this.config.topicIid);

        console.log(`Topic ${this.config.topicIid} is ${isFavorite ? "" : "not "}a favorite`);

        // Initialize Favorite button
        this.favoriteButton = new FavoriteButton("topicFavBtn", {
            class: "float-right",
            initialState: isFavorite,
            toggleHandler: function(newState, favBtnObj) {
                self.handleToggleFavorite(newState, favBtnObj);
            }
        });
    }
}