


import { Config } from "../infohub-setup";



class UrlManagerImpl
{
    constructor()
    {

    }

    getThumbnailUrlForImageIid(imageIid)
    {
        return `${ Config.baseUrl}resources/img/mobj/thumb/${imageIid}.jpg`;
    }
}


export const UrlManager = new UrlManagerImpl();
