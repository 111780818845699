

import { CitationFormatterBase } from "./CitationFormatterBase";


export class CitationFormatterAupBiblio extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        let result = "";

        //
        //  AUTHOR(S)
        //
        result += this.generateAuthorString(citationObj, config);

        //
        // CITATION BODY
        //
        if (citationObj.pubType === "book")
        {
            result += `<i>${citationObj.title}</i>`;

            if (!result.endsWith("."))
            {
                result += ".";
            }
            result += " ";

            result += `${citationObj.publisher.loc}: ${citationObj.publisher.name}, ${citationObj.pubYear}`;

            // // Optional page range
            // if (citationObj.pos)
            // {
            //     result += `, ${citationObj.pos}`;
            // }

            result += ".";
        }
        else // periodical
        {
            // Article title
            if (citationObj.title)
            {
                result += `"${citationObj.title}`;

                if (!result.endsWith("."))
                {
                    result += ".";
                }

                result += '" ';
            }

            // Periodical title in italics
            result += `<i>${citationObj.publishedIn.title}</i> `;

            // Volume number, Issue number, and Date
            let pageRangeSeparator = "";

            if (citationObj.publishedIn.volume && citationObj.publishedIn.volume !== "")
            {
                result += `${citationObj.publishedIn.volume}, no. ${citationObj.publishedIn.issue} (${citationObj.publishedIn.date})`;
                pageRangeSeparator = ":";
            }
            else // no volume, so use date only
            {
                result += "," + citationObj.publishedIn.date;
                pageRangeSeparator = ",";
            }

            // // Page range
            // if (citationObj.publishedIn.pages)
            // {
            //     result += `${pageRangeSeparator} ${citationObj.publishedIn.pages}`;
            // }


            result += ".";
        }

        return result;
    }


    generateAuthorString(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let authorStr = "";

        if (authorsList.length == 1)
        {
            authorStr = authorsList[0].getSurnameCommaFirstNameMiddleInitialWithPrefix();
        }
        else if (authorsList.length == 2)
        {
            authorStr = `${authorsList[0].getSurnameCommaFirstNameMiddleInitialWithPrefix()}, and ${authorsList[1].getFirstNameMiddleInitialSurnameWithPrefix()}`;
        }
        else if (authorsList.length >= 3 && authorsList.length <= 10)
        {
            // First author is last, first
            authorStr = authorsList[0].getSurnameCommaFirstNameMiddleInitialWithPrefix();

            // Other authors first last
            for (let i = 1; i < authorsList.length - 1; ++i)
            {
                authorStr += ", ";
                authorStr += authorsList[i].getFirstNameMiddleInitialSurnameWithPrefix();
            }

            // Last author
            authorStr += ", and ";
            authorStr += authorsList[authorsList.length - 1].getFirstNameMiddleInitialSurnameWithPrefix();
        }
        else if (authorsList.length > 10)
        {
            // list first 7 followed by et al.
            authorStr = authorsList[0].getSurnameCommaFirstNameMiddleInitialWithPrefix();

            for (let i = 1; i < 7; ++i)
            {
                authorStr += ", ";
                authorStr == authorsList[i].getFirstNameMiddleInitialSurnameWithPrefix();
            }

            authorStr += " et al.";
        }

        // Add role
        if (authorsList.length > 0 && authorsList[0].props.role)
        {
            const abbrevRole = (authorsList.length == 1 ? this.getAbbreviatedRole(authorsList[0].props.role) : this.getAbbreviatedRolePlural(authorsList[0].props.role));
            authorStr += ", ";
            authorStr += abbrevRole.toLowerCase();
        }

        if (authorsList.length > 0)
        {
            if (!authorStr.endsWith("."))
            {
                authorStr += ".";
            }

            authorStr += " ";
        }

        return authorStr;
    }
}