

import $ from "jquery";

import { FavoritesManager } from "../hub-favorites-manager";
import { Scroller } from "../scroller";


export class TopicsHomepage
{
    constructor()
    {
        this.favsMgr = new FavoritesManager();

        this.init();
    }


    init()
    {
        console.log("Initializing TopicsHomepage...");

        // Initializes the Favorite Publications section
        this.initFavoriteTopics();
    }

    initFavoriteTopics()
    {
        const self = this;

        console.log("Initializing Favorite Topics...");

        const favTopics = this.favsMgr.getAllFavorites("topics");

        console.log(`${favTopics.length} topics in favorites`);



        if (favTopics && favTopics.length > 0)
        {
            const scrollerDiv = $("#favTopicsScrollerDiv");
            const itemsDiv = $(scrollerDiv).find(".items");

            // Populate all items
            const itemsPerColumn = 5;


            for (let i = 0; i < favTopics.length; i += itemsPerColumn)
            {
                $(itemsDiv).append(this.makeColumn(favTopics, i, itemsPerColumn));
            }

            $("#noFavsMsg").hide();
            $(scrollerDiv).show();

            // Initialize the Scroller
            this.scroller = new Scroller("favTopicsScrollerDiv", {
                blocksPreloaded: Math.ceil(favTopics.length / itemsPerColumn),
                itemsPerBlock: itemsPerColumn,
                itemsPerColumn: itemsPerColumn,
                totalItems: favTopics.length,
                blockDataLoader: function(blockNum, successCallback, errorCallback, scrlr) {
                    successCallback([]);
                },
                populateColumn: function(columnDiv, columnNumber, blockNumber, blockData, scrlr) {
                    console.log(`Populating column ${columnNumber}, block ${blockNumber}`);
                }
            });
        }
    }



    makeColumn(favTopicObjs, startOffset, itemsPerColumn)
    {
        const enclosingDiv = $("<div/>", { class: "item" } );
        var ul = $("<ul/>", { class: "index-row" }).appendTo($(enclosingDiv));

        for (let i = startOffset; i < startOffset + itemsPerColumn && i < favTopicObjs.length; ++i)
        {
            const topicObj = favTopicObjs[i];

            const title = topicObj.title;
            const topicPgUrl = topicObj.topicPgUrl;
            const totalRefs = topicObj.totalRefs;

            const html = `<div class="media-body"><a href="${topicPgUrl}">${title}</a></div>
<span class="badge badge-pill badge-primary d-flex ml-3">${totalRefs}</span>`;
            $(ul).append( $("<li/>", { class: "media" }).html(html) );
        }

        return $(enclosingDiv);
    }

}

