


import $ from 'jquery';

import {Config} from "./infohub-setup";
import {Range} from "./range";
import {ProgressiveLoader} from "./progressiveloader";
import { CitationManager } from "./citations/CitationManager";


console.log("EntityRefsByPubComponent.js entered...");



export class EntityRefsByPubComponent
{
    constructor(divId, config)
    {
        this.enclosingDivId = divId;
        this.populateConfig(config);
        this.init();
    }

    closeRefs(btn)
    {
        const self = this;
        const pubIid = $(btn).closest(".xref").attr("data-xref-pubiid");

        console.log("Closing refs for pub " + pubIid);

        $("#refsnippets_" + pubIid).hide(333);

        $(btn).closest(".infotoolbar")
              .hide(333)
              .after( $("<a/>", { class: "action-link", "data-pubiid": pubIid, href: "#" }).click(function () { self.showRefs(this); return false; }).text("Show References \u203a"));

        return false;
    }

    createNewElems(data)
    {
        return this.createNewElemsWithFilter(data, null);
    }

    createNewElemsWithFilter(data, filterFunc)
    {
        const self = this;
        const elemsList = [];

        let numOfRefsLoaded = 0;
        let refPubIid;

        for (let i = 0; i < data.refs.length; ++i)
        {
            const ref = data.refs[i];
            refPubIid = data.refs[i].properties.pubIid;

            if (filterFunc != null && !filterFunc(ref))
            {
                continue;
            }
            const newDiv = $("<div/>", { class: "snippet" });

            newDiv.append('"');

            for (let snipElem = 0; snipElem < ref.content.length; ++snipElem)
            {
                const elem = ref.content[snipElem];
                if (typeof elem === "string")
                {
                    newDiv.append(elem);
                }
                else // ref object
                {
                    const refEntIid = elem.entityIid;
                    const url = elem.url;
                    const txt = elem.text;

                    if (refEntIid === entityIid)
                    {
                        // self
                        newDiv.append( $("<span/>", { class: "self" }).text(txt) );
                        ++numOfRefsLoaded;
                    }
                    else
                    {
                        newDiv.append( $("<a/>", { href: url }).text(txt) );
                    }
                }
            }

            newDiv.append('"');

            // Add a Read link if docpos is available
            if (ref.properties.docPosition)
            {
                const docPos = parseInt(ref.properties.docPosition) + 1;
                const fileUrl = ref.properties.externalLink;
                const pubIid = ref.properties.pubIid;

                newDiv.append(" (");
                newDiv.append( $("<a/>", { href: "#" } )
                                    .click(function() {
                                                const readUrl = self.basePdfReaderUrl
                                                    + "?file=" + encodeURIComponent(fileUrl)
                                                    + "#page=" +  docPos;
                                                console.log(`Reading pdf ${fileUrl} page ${docPos} pub ${pubIid}`);
                                                window.open(readUrl, "khub_" + pubIid);
                                                return false;
                                            })
                                    .text("p" + docPos ));
                newDiv.append(" ");
                newDiv.append( $("<i/>", { class: "fa fa-external-link", "aria-hidden": "true" } ).text(" "));
                newDiv.append(")");
            }

            // Citation Button
            newDiv.append(" ");
            const linkProps = { href: "#", title: "Cite This", class: "img-link citation-btn", "data-citation-type": "ref" };
            if (ref.properties.docPosition)
            {
                linkProps["data-citation-pos"] = parseInt(ref.properties.docPosition);
            }
            newDiv.append( $("<a/>", linkProps ).click(function() { self.citationGenerator.handleClick(this); return false; }).append( $("<img/>", { src: Config.resourceUrl("img/add-citation.svg") } )) );

            elemsList.push(newDiv);
        }

        // Update the number of loaded references
        console.log("Added " + numOfRefsLoaded + " for publication " + refPubIid);
        const currCount = parseInt($("#refCountShowing_" + refPubIid).text());
        $("#refCountShowing_" + refPubIid).text(currCount + numOfRefsLoaded);

        return elemsList;
    }


    createNewPubElems(data)
    {
        const self = this;
        const newPubIids = [];
        const elems = [];

        console.log("Creating new elements");

        const pubs = data.pubs;

        for (let i = 0; i < pubs.length; ++i)
        {
            const pub = pubs[i];
            newPubIids.push(pub.pubIid);

            const div = $("<div/>");
            $(div).append($("<hr/>"));

            const rowDiv = $("<div/>", { class: "row xref", "data-xref-pubiid": pub.pubIid, "data-refs-mode": "all" }).appendTo($(div));

            const colDiv = $("<div/>", { class: "col" }).appendTo($(rowDiv));

            const headerDiv = $("<div/>", { class: "media pub-list-entry" }).appendTo(colDiv);

            const pubImg = $("<a/>", { href: pub.pubUrl }).appendTo(headerDiv);
            $("<img/>", { class: "d-flex mr-3 cover-img", src: pub.coverThumbUrl, alt: pub.title }).appendTo(pubImg);

            const headerBody = $("<div/>", { class: "media-body" }).appendTo($(headerDiv));
            const pubTitleDiv = $("<div/>", { class: "work-title" }).appendTo($(headerBody));
            $("<a/>", { href: pub.pubUrl }).text(pub.title).appendTo($(pubTitleDiv));
            $("<hr/>").appendTo($(pubTitleDiv));
            const topToolbarDiv = $("<div/>", { class: "row infotoolbar" }).appendTo($(headerBody));

            const refCountDiv = $("<div/>", { class: "col-7" }).appendTo($(topToolbarDiv));
            $("<span/>", { class: "text-hi" }).text(pub.refCount).appendTo(refCountDiv);
            $(refCountDiv).append(" references, ");
            $("<span/>", { class: "text-hi" }).text( Math.round(parseFloat(pub.refDist) * 10000) / 10000).appendTo($(refCountDiv));
            $(refCountDiv).append(" distribution");

            const slDiv = $("<div/>", { class: "col-5" }).appendTo(topToolbarDiv);
            const slButton = $("<span/>", { class: "sparkline-button" }).appendTo(slDiv);
            $("<span/>", { class: "sparkline float-left", id: "slDiv_" + pub.pubIid }).appendTo(slButton);
            $(slButton).append($("<span/>").html("&nbsp;&nbsp;"));
            const searchBtn = $("<button/>", { class: "btn btn-outline-dark btn-sm btn-secondary", type: "button" }).appendTo(slButton);
            $("<i/>", { class: "fa fa-search", "aria-hidden": "true" }).appendTo(searchBtn);

            $("<div/>", { id: "refsnippets_" + pub.pubIid, "data-items-per-block": refsPerPage, "data-total-items": pub.refCount, "data-blocks-preloaded": "0" }).appendTo(colDiv);

            const refsToolbar = $("<div/>", { class: "infotoolbar" }).css( "display", "none" ).appendTo(colDiv);

                const rtShowSpan = $("<span/>").appendTo(refsToolbar);

                    const refsShowLblSpan = $("<span/>", { class: "refsShowLbl" }).appendTo($(rtShowSpan));
                        $(refsShowLblSpan).append("Showing References ");
                        $("<span/>", { class: "larger-text" }).text("1").appendTo(refsShowLblSpan);
                        $(refsShowLblSpan).append(" to ");
                        $("<span/>", { class: "larger-text", id: "refCountShowing_" + pub.pubIid }).text("0").appendTo(refsShowLblSpan);
                        $(refsShowLblSpan).append(" of ");

                    const refRangeSelDiv = $("<div/>", { class: "btn-group ref-range-sel" } ).css("display", "inline-block").appendTo($(rtShowSpan));
                        $("<button/>", { class: "btn btn-outline-dark btn-sm btn-secondary dropdown-toggle", type: "button", "data-toggle": "dropdown" }).text(`All ${pub.refCount} References`).appendTo($(refRangeSelDiv));
                        const refRangeSelOpts = $("<ul/>", { class: "dropdown-menu", role: "menu" }).appendTo($(refRangeSelDiv));
                           $("<li/>").append( $("<a/>", { class: "dropdown-item", "data-mode": "all" } ).text(`All ${pub.refCount} References`).click(function() { self.handleRangeSel(this); }) ).appendTo($(refRangeSelOpts));
                           $("<li/>").append( $("<a/>", { class: "dropdown-item", "data-mode": "pgs" } ).text("References in Page Range...").click(function() { self.handleRangeSel(this); }) ).appendTo($(refRangeSelOpts));

                    $("<button/>", { class: "btn btn-outline-dark btn-sm btn-secondary extra-left-margin" }).click(function() { self.loadMoreRefs(this); return false;}).text("More").appendTo(rtShowSpan);

            const rtTools = $("<span/>", { class: "float-right" }).appendTo(refsToolbar);
            $(rtTools).append("Show ");
            const modeSelDiv = $("<div/>", { class: "btn-group scroller-mode-select" }).css("display", "inline-block").appendTo(rtTools);
            const modeBtn = $("<button/>", { class: "btn btn-outline-dark btn-sm btn-secondary dropdown-toggle", type: "button", "data-toggle": "dropdown" }).text("First ").appendTo(modeSelDiv);
            const modesUl = $("<ul/>", { class: "dropdown-menu", role: "menu" }).appendTo(modeSelDiv);
            $(modesUl).append( $("<li/>").append($("<a/>", { class: "dropdown-item"}).text("First") ) );
            $(modeSelDiv).append(" References");

            const rtCloseBtn = $("<button/>", { class: "btn btn-outline-dark btn-sm btn-secondary extra-left-margin icon-button" }).click(function() { self.closeRefs(this); return false; }).appendTo(rtTools);
            $("<i/>", { class: "fa fa-times", "aria-hidden": "true" }).appendTo(rtCloseBtn);


            $("<a/>", { class: "action-link", "data-pubiid": pub.pubIid, href: "#" }).click(function() { self.showRefs(this); return false; }).text("Show References \u203a").appendTo(colDiv);

            elems.push(div);

            // Setup sparkline
            const refCounts = [];
            const chunkPos = [];

            for (let chunkIndex = 0; chunkIndex < pub.refPerChunk.length; ++chunkIndex)
            {
                const refInfo = pub.refPerChunk[chunkIndex];
                refCounts.push(refInfo.refs);
                if (refInfo.startPos === refInfo.endPos)
                {
                    chunkPos.push(refInfo.startPos);
                }
                else
                {
                    chunkPos.push(refInfo.startPos + "-" + refInfo.endPos);
                }
            }

            this.pubRefInfo[pub.pubIid] = {
                "citation": pub.citation,
                "refCounts": refCounts,
                "docPos": chunkPos,
                "loader": null
            };
        }

        return { elems: elems, postAdd: function() {
                for (let i = 0; i < newPubIids.length; ++i) {
                    const pubIid = newPubIids[i];
                    self.initializeSparklineForPub(pubIid);
                    self.pubRefInfo[pubIid].loader = self.createRefsLoader(pubIid);
                }
            } };
    }

    createRefsLoader(pubIid)
    {
        const self = this;

        return new ProgressiveLoader("refsnippets_" + pubIid, {
            showMoreButton: false,
            blockDataLoader: function(blockOffset, successCallback, errorCallback, ldr) {
                var url = Config.versionedDataUrl("pubs/" + pubIid + "/entrefs/" + self.entityIid + "/entref-details-" + blockOffset + ".json");
                $.ajax({
                    url: url
                }).done(function(data, textStatus, jqXHR) {
                    successCallback(data);
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    errorCallback({ "textStatus": textStatus, "errorThrown": errorThrown } );
                });
            },
            createNewElems: function(data) {
                console.log("Creating new elements");
                return self.createNewElems(data);
            }
        });
    }


    dismissPopovers()
    {
        console.log("Closing popovers..");

        $('[data-original-title]').popover('hide');

        if (this.doClearPopover != null)
        {
            this.doClearPopover();
            this.doClearPopover = null;
        }

        this.popoverShowing = false;
    }

    handleRangeSel(selectedItem)
    {
        const self = this;
        const mode = $(selectedItem).attr("data-mode");
        const pubIid = $(selectedItem).closest(".xref").attr("data-xref-pubiid");
        const xrefDiv = $(selectedItem).closest(".xref");
        const currentMode = $(xrefDiv).attr("data-refs-mode");
        const enclosingDiv = $(selectedItem).closest("div");

        console.log("Handling range selection");
        console.log("Selected item: " + mode + ", current mode: " + currentMode + ", pubIid: " + pubIid);

        if (mode === currentMode && mode !== "pgs")
        {
            console.log("No change in selection");
            return false;
        }

        console.log("New mode selected: " + mode);

        const selectorBtn = $(enclosingDiv).find("button");
        const prevRefRangeMode = $(selectorBtn).text();
        const itemText = $(selectedItem).text();

        // Update selector button
        $(selectorBtn).text(itemText);

        if (mode === "pgs")
        {
            // Show popover
            if (!$(enclosingDiv).data("bs.popover") || !$(enclosingDiv).attr('data-popoverAttached'))
            {
                $(enclosingDiv).popover('destroy').popover({
                    placement:'right',
                    trigger:'manual',
                    html:true,
                    title: 'Enter Page Range',
                    content:'Pages: <input class="rng-sel-txt-input" type="text" style="width: 5em;" />&nbsp; <button class="refsPgRngUpdtBtn"><i class="fa fa-check" /></button>&nbsp;&nbsp;<button class="popover-dismiss"><i class="fa fa-close" /></button>'
                });
                $(enclosingDiv).attr('data-popoverAttached', true);
            }
            $(enclosingDiv).popover('show');

            setTimeout(function() {
                self.popoverShowing = true;

                // Focus on the popover input field
                $(".rng-sel-txt-input").focus().keypress(function(event){
                    const keycode = (event.keyCode ? event.keyCode : event.which);
                    if(keycode == '13'){
                        self.updateRefsPageRange(pubIid, xrefDiv, selectorBtn);
                    }
                });

                self.doClearPopover = function() {
                    $(selectorBtn).text(prevRefRangeMode);
                    $(selectedItem).closest(".xref").attr("data-refs-mode", currentMode);
                };

                $(".popover-dismiss").click(function() {
                    self.dismissPopovers();
                });

                $(".refsPgRngUpdtBtn").click(function() {
                    self.updateRefsPageRange(pubIid, xrefDiv, selectorBtn);
                });
            }, 1);
        }
        else if (mode === "all")
        {
            $(xrefDiv).find(".more-refs-btn").show();
            $(xrefDiv).attr("data-refs-mode", "all");

            const refsLblSpan = $(xrefDiv).find(".infotoolbar .refsShowLbl");
            $(refsLblSpan).empty()
                .append("Showing References ")
                .append( $("<span/>", { class: "larger-text" }).text("1") )
                .append(" to ")
                .append( $("<span/>", { class: "larger-text", id: "refCountShowing_" + pubIid } ).text("0") )
                .append(" of ");

            // Clear items & reset the loader
            $("#refsnippets_" + pubIid).empty();
            const loader = this.pubRefInfo[pubIid].loader;
            loader.reset();
            loader.loadMore();
        }

    }


    init()
    {
        const self = this;

        this.initializePubsLoader();

        // Citation Generator
        this.citationGenerator = new CitationManager({
            "citationSource": function(citationElem) {
                var citType = $(citationElem).attr("data-citation-type");
                var pubIid = $(citationElem).closest(".xref").attr("data-xref-pubiid");
                var posStr = $(citationElem).attr("data-citation-pos");
                var pos = parseInt(posStr) + 1;

                if (citType === "ref")
                {
                    return {
                        "metadata": self.pubRefInfo[pubIid].citation,
                        "pos": pos
                    };
                }
                else
                {
                    throw "Unsupported citation type";
                }
            }
        });

        for (let pubIid in this.pubRefInfo)
        {
            console.log(`Initializing for pub ${pubIid}`);

            this.pubRefInfo[pubIid]["loader"] = this.createRefsLoader(pubIid);

            this.initializeSparklineForPub(pubIid);

            // Enable Range selectors
            $(".ref-range-sel a").each(function() {
                console.log("Handling link element");
                $(this).click(function() {
                    self.handleRangeSel(this);
                    return true;
                });
            });

            $('html').on('click', function(e) {
                if (self.popoverShowing
                    && !$(e.target).parents().is(".popover-body")
                    && !$(e.target).hasClass("popover-body")
                    //&& typeof $(e.target).data('original-title') == 'undefined'
                    //&& !$(e.target).parents().is('.popover.in')
                   )
                {
                    self.dismissPopovers();
                }
            });
        }
    }

    initializePubsLoader()
    {
        const self = this;

        this.pubsLoader = new ProgressiveLoader(this.enclosingDivId, {
            showMoreButton: false,
            blockDataLoader: function(blockOffset, successCallback, errorCallback, ldr) {
                console.log("Loading more pubs...");
                const url = Config.versionedDataUrl("refs/entity/" + self.entityIid + "/pubs-refing-ent-" + blockOffset + ".json");
                console.log("Loading more pubs, URL: " + url);
                $.ajax({
                    url: url
                }).done(function(data, textStatus, jqXHR) {
                    successCallback(data);

                }).fail(function (jqXHR, textStatus, errorThrown) {
                    errorCallback({ "textStatus": textStatus, "errorThrown": errorThrown } );
                });
            },
            createNewElems: function(data) {
                console.log("Creating new elements");
                return self.createNewPubElems(data);
            }
        });
    }


    initializeSparklineForPub(pubIid)
    {
        const self = this;

        const refCounts = this.pubRefInfo[pubIid].refCounts;
        
        const spklnDivSelector = "#slDiv_" + pubIid

        $(spklnDivSelector).sparkline(refCounts, {
            height: "2em",
            width: "200px",
            minSpotColor: false,
            tooltipClassname: "sparkline-tooltip",
            tooltipFormatter: function(sparkline, options, fields) {
                let x = fields.x;
                let refs = refCounts[x];
                let pos = self.pubRefInfo[pubIid].docPos[x];
                let multiPages = (pos.indexOf("-") > 0);
                return "<div class='tip'>" + refs + " Reference" + (refs != 1 ? "s" : "") + "<br/><span class='pos'>" + (multiPages ? "pgs " : "pg ") + pos  + "</span></div>";
            }
        });

        $(spklnDivSelector).bind('sparklineClick', function(ev) {

            const spanId = $(ev.currentTarget).attr("id");
            const pubIid = spanId.substring(spanId.indexOf("_") + 1);
            const sparkline = ev.sparklines[0];
            const region = sparkline.getCurrentRegionFields();

            const xVal = region.x;

            const docPos = self.pubRefInfo[pubIid].docPos[xVal];

            if (typeof docPos === "undefined")
            {
                return true;
            }

            const rowDiv = $("div[data-xref-pubiid='" + pubIid + "']");
            const btnDiv = $(rowDiv).find(".ref-range-sel button");

            // X = index of value clicked on (segment #), Y = value at that point (# of references)
            console.log(`Clicked on sparkline id=${spanId}, pubIid=${pubIid}, docPos=${docPos}, x=${region.x}, y=${region.y}`);

            self.updateRefsPageRangeTo(pubIid, docPos, rowDiv, btnDiv);

            return true;
        });

    }

    loadMorePubs(btn)
    {
        console.log(`Loading ${this.pubsPerPage} more publications`);
        this.pubsLoader.loadMore();
    }

    loadMoreRefs(btn)
    {
        console.log("Load more refs: " + $(btn).attr("id"));
        var enclosingDiv = $(btn).closest(".xref");

        var pubIid = $(enclosingDiv).attr("data-xref-pubiid");
        var currentMode = $(enclosingDiv).attr("data-refs-mode");

        console.log("Loading more refs for pub " + pubIid + " in mode " + currentMode);

        if (currentMode === "all")
        {
            this.pubRefInfo[pubIid].loader.loadMore();
        }
        else if (currentMode === "pgs")
        {
            var refsDiv = $("#refsnippets_" + pubIid);
            var startBlock = parseInt($(refsDiv).attr("data-next-block"));
            var endBlock = parseInt($(refsDiv).attr("data-end-block"));
            var pageRange = new Range($(refsDiv).attr("data-pg-range"));
            var numLoaded = parseInt($(refsDiv).attr("data-num-loaded"));
            var maxToLoad = numLoaded + 10;

            console.log("Loading more page range refs, startBlock=" + startBlock + ", endBlock=" + endBlock + ", pgRange=" + $(refsDiv).attr("data-pg-range") + ", numLoaded=" + numLoaded + ", maxToLoad=" + maxToLoad);

            this.loadPageRangeRefs(startBlock, endBlock, pageRange, pubIid, numLoaded, maxToLoad);
        }
    }

    loadPageRangeRefs(currBlock, endBlock, pageRange, pubIid, numAlreadyLoaded, maxToLoad)
    {
        console.log("Initiating load of refs block " + currBlock + " for pub " + pubIid);

        const self = this;
        const url = Config.versionedDataUrl("pubs/" + pubIid + "/entrefs/" + entityIid + "/entref-details-" + (currBlock * this.refsPerPage) + ".json");

        $.ajax({
            url: url
        }).done(function(data, textStatus, jqXHR) {
            const elems = self.createNewElemsWithFilter(data, function(ref) {
                console.log("Ref is on page: " + ref.properties.docPosition);
                return pageRange.contains(parseInt(ref.properties.docPosition) + 1);
            });

            const refsDiv = $("#refsnippets_" + pubIid);

            for (let i = 0; i < elems.length; ++i)
            {
                $(refsDiv).append(elems[i]);
            }

            const numLoaded = elems.length;
            const totalLoaded = numAlreadyLoaded + numLoaded;
            const moreRefsMayExist = currBlock < endBlock && elems.length === refsPerPage;
            const needToLoadMore = moreRefsMayExist && totalLoaded < maxToLoad && elems.length === refsPerPage;

            // Update label
            const rowDiv = $("div[data-xref-pubiid='" + pubIid + "']");
            const lblDiv = $(rowDiv).find(".infotoolbar span.refsShowLbl");
            const moreBtn = $(lblDiv).parent().find(".more-refs-btn");
            $(lblDiv).empty();

            if (moreRefsMayExist)
            {
                $(lblDiv).append("Showing First ")
                    .append( $("<span/>", { class: "larger-text" } ).text(totalLoaded) )
                    .append(" References in ");
                $(moreBtn).show();
                $(refsDiv).attr("data-next-block", currBlock + 1)
                    .attr("data-end-block", endBlock)
                    .attr("data-pg-range", pageRange.toString())
                    .attr("data-num-loaded", totalLoaded);
            }
            else
            {
                $(lblDiv).append("Showing All References in ");
                $(moreBtn).hide();
            }

            // Load more if needed
            if (needToLoadMore) {
                this.loadPageRangeRefs(currBlock + 1, endBlock, pageRange, pubIid, totalLoaded, maxToLoad);
            }

        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.log("Failed to load references for pub " + pubIid + ", block " + currBlock);
        });
    }



    populateConfig(config)
    {
        console.log("EntityRefsByPubComponent config: " + JSON.stringify(config));

        this.doClearPopover = null;
        this.popoverShowing = false;

        this.entityIid = config.entityIid;
        this.pubRefInfo = config.pubRefInfo;
        this.refsPerPage = config.refsPerPage;
        this.pubsPerPage = config.pubsPerPage;
        this.pubsLoaded = config.pubsLoaded;
        this.totalPubs = config.totalPubs;
        this.basePdfReaderUrl = config.basePdfReaderUrl;
    }

    showRefs(btn)
    {
        const pubIid = $(btn).closest(".xref").attr("data-xref-pubiid");

        console.log("Showing refs for pub " + pubIid);

        if ($("#refsnippets_" + pubIid + " .snippet").length == 0)
        {
            console.log("Loading refs for " + pubIid);
            this.pubRefInfo[pubIid].loader.loadMore();
        }
        $("#refsnippets_" + pubIid).show(500);
        $(btn).prev(".infotoolbar").show(500);
        $(btn).hide(333);
        return false;
    }

    switchToPageRangeRefs(pubIid, pgRangeStr)
    {
        console.log("Switching " + pubIid + " to page range " + pgRangeStr);

        const refsDiv = $("#refsnippets_" + pubIid);

        // Clear existing snippets
        $(refsDiv).empty();

        // TODO: add loading symbol

        // Fetch first page of results
        const pgRange = new infohub.Range(pgRangeStr);
        const refsInfo = this.pubRefInfo[pubIid];

        let startI = -1, endI = -1;


        for (let i = 0; i < refsInfo.docPos.length; ++i)
        {
            const posRange = new Range(refsInfo.docPos[i]);
            if (pgRange.intersects(posRange))
            {
                if (startI < 0)
                {
                    startI = i;
                    endI = i;
                }
                else
                {
                    endI = i;
                }
            }
            else if (endI > 0)
            {
                break;
            }
        }

        console.log("Page range includes chunks " + startI + " to " + endI);

        let startRefOffset = 0;
        for (let i = 0; i < startI; ++i)
        {
            startRefOffset += refsInfo.refCounts[i];
        }

        let endRefOffset = startRefOffset;
        for (let i = startI; i <= endI; ++i)
        {
            endRefOffset += refsInfo.refCounts[i];
        }

        console.log("Page range includes refs " + startRefOffset + " to " + (endRefOffset - 1));


        const startBlock = Math.floor(startRefOffset / refsPerPage);
        const endBlock = Math.floor(endRefOffset / refsPerPage);

        console.log("Page range includes reference blocks " + startBlock + " to " + endBlock);

        this.loadPageRangeRefs(startBlock, endBlock, pgRange, pubIid, 0, 10);
    }

    updateRefsPageRange(pubIid, xrefDiv, selectorBtn)
    {
        const pgRange = $(".rng-sel-txt-input").val();

        console.log("Updated pub " + pubIid + " refs to page range " + pgRange);

        this.dismissPopovers();

        this.updateRefsPageRangeTo(pubIid, pgRange, xrefDiv, selectorBtn);
    }

    updateRefsPageRangeTo(pubIid, pgRange, xrefDiv, selectorBtn)
    {
        // Update selector text
        $(selectorBtn).text(`Page${pgRange.indexOf("-") > 0 ? "s" : ""} ${pgRange}`);

        // Update current mode
        $(xrefDiv).attr("data-refs-mode", "pgs").attr("data-curr-pg-range", pgRange);

        this.switchToPageRangeRefs(pubIid, pgRange);
    }

}