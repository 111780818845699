


import { knowledgeHubUtils } from "./hub-utils";


export class FavoritesManager
{
    constructor()
    {
        this.favorites = { };
    }

    addToFavorites(objType, objKey, objVal)
    {
        console.log(`Adding favorite ${objType} ${objKey}`);

        const favs = this.getFavoritesOfType(objType);

        favs[objKey] = objVal;

        this.saveFavoritesOfType(objType);

        knowledgeHubUtils.event("fav", [ "add", objType, objKey ]);
    }

    getFavorite(objType, objKey)
    {
        return this.getFavoritesOfType(objType)[objKey];
    }

    getAllFavorites(objType)
    {
        const favs = this.getFavoritesOfType(objType);
        const result = [ ];

        for (let key in favs)
        {
            result.push(favs[key]);
        }

        return result;
    }


    isFavorite(objType, objKey)
    {
        return (objKey in this.getFavoritesOfType(objType));
    }

    removeFromFavorites(objType, objKey)
    {
        console.log(`Removing favorite ${objType} ${objKey}`);

        const favs = this.getFavoritesOfType(objType);
        delete favs[objKey];
        this.saveFavoritesOfType(objType);

        knowledgeHubUtils.event("fav", [ "rm", objType, objKey ]);
    }

    clearFavorites(objType)
    {
        const lsItemKey = this.getLocalStorageKeyForType(objType);
        window.localStorage.removeItem(lsItemKey);
    }

    saveFavoritesOfType(objType)
    {
        console.log(`Saving favorites of type ${objType}`);

        const lsItemKey = this.getLocalStorageKeyForType(objType);
        const favs = this.getFavoritesOfType(objType);

        window.localStorage.setItem(lsItemKey, JSON.stringify(favs));
    }

    getFavoritesOfType(objType)
    {
        if (!(objType in this.favorites))
        {
            const lsItemKey = this.getLocalStorageKeyForType(objType);

            const valStr = window.localStorage.getItem(lsItemKey);

            if (typeof valStr === "undefined" || valStr == null || valStr === "")
            {
                this.favorites[objType] = { };
            }
            else
            {
                this.favorites[objType] = JSON.parse(valStr);
            }
        }

        return this.favorites[objType] || { };
    }

    getLocalStorageKeyForType(objType)
    {
        return `cvm.khub.favs.${objType}`;
    }
}