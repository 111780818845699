

import { cookieManager } from "./hub-cookie-manager";
import { knowledgeHubUtils } from "./hub-utils";


export function infohubInit()
{
    // Initialize Cookies
    const isFirstTimeVisitor = cookieManager.initializeCookies();

    // Show welcome message to first time visitors UNLESS they are on the site homepage
    if (isFirstTimeVisitor && window.location.pathname !== "/")
    {
        knowledgeHubUtils.showWelcomeMessage();
    }

}

