


import $ from 'jquery';
import {Config} from "./infohub-setup";


//console.log("infohub-dataloader.js entered...");

export const InfohubDataLoader = {

    loadEntityRefSnippets: function(pubIid, entityIid, offset, successCallback, errorCallback)
    {
        console.log(`Loading Entity Reference Snippets for pub ${pubIid}, entity ${entityIid}, offset ${offset}`);

        const url = Config.versionedDataUrl("pubs/" + pubIid + "/entrefs/" + entityIid + "/entref-details-" + offset + ".json");

        $.ajax({
            url: url
        }).done(function(data, textStatus, jqXHR) {
            console.log("Finished loading url " + url);
            successCallback(data);
        }).fail(function (jqXHR, textStatus, errorThrown) {
            console.log("Error loading url " + url);
            errorCallback({ "status": textStatus, "error": errorThrown });
        });
    }
};