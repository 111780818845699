

import { CitationFormatterBase } from "./CitationFormatterBase";


export class CitationFormatterAupNoteShort extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        let result = "";

        //
        //  AUTHOR(S)
        //
        result += this.generateAuthorString(citationObj, config);

        //
        // ITALICIZED TITLE
        //
        result += `<i>${citationObj.title}</i>`;

        //
        // PAGES IF PRESENT
        //
        if (citationObj.pos)
        {
            result += `, ${citationObj.pos}`;
        }

        //
        // PERIOD
        //
        result += ".";

        return result;
    }


    generateAuthorString(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let authorStr = "";

        switch (authorsList.length)
        {
            case 0:
                break;

            case 1:
                authorStr = authorsList[0].getSurnameOrFullName();
                break;

            case 2:
                authorStr = `${authorsList[0].getSurnameOrFullName()} and ${authorsList[1].getSurnameOrFullName()}`;
                break;

            case 3:
                authorStr = `${authorsList[0].getSurnameOrFullName()}, ${authorsList[1].getSurnameOrFullName()}, and ${authorsList[2].getSurnameOrFullName()}`;
                break;

            default:
                authorStr = `${authorsList[0].getSurnameOrFullName()} et al.`;
                break;
        }

        // Add role
        // if (authorsList.length > 0 && authorsList[0].props.role)
        // {
        //     const abbrevRole = (authorsList.length == 1 ? this.getAbbreviatedRole(authorsList[0].props.role) : this.getAbbreviatedRolePlural(authorsList[0].props.role));
        //     authorStr += ", ";
        //     authorStr += abbrevRole.toLowerCase();
        // }

        if (authorsList.length > 0)
        {
            authorStr += ", ";
        }

        return authorStr;
    }
}