


// The following enables the Babel Polyfill which provides for compatibility with IE11, for example.  See http://babeljs.io/docs/en/babel-polyfill/
import "babel-polyfill";


console.log("hub.js entered");

require("./modernizr");

// jQuery
import $ from 'jquery';
require('jquery.scrollto');
require('./jquery.sparkline');

// Bootstrap
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
require("font-awesome-webpack-4");

// KnowledgeHub init
import "./infohub-setup";
import  { infohubInit } from "./infohub-init";


// HOPSCOTCH
import * as hopscotch from "./hopscotch";
import "./hopscotch.min.css";


import './infohub.css';

import { FavoriteButton } from "./component-favorite-button";
import { FavoritesManager } from "./hub-favorites-manager";
import { GuidedTour } from "./hub-guided-tour";
import {InfohubDataLoader} from "./infohub-dataloader";
import {InfohubDomUtils} from "./infohub-dom-utils";
import {Scroller} from "./scroller.js";
import {ProgressiveLoader} from "./progressiveloader";
import {Range} from "./range";
import {EntityRefsByPubComponent} from "./page-entityrefsbypub";

import { PublicationsHomepage } from "./pages/PublicationsHomepage";
import { TopicHompage } from "./pages/TopicHomepage";
import { TopicsHomepage } from "./pages/TopicsHomepage";

import { VideoPlayer } from "./controls/VideoPlayer";

import { cookieManager } from "./hub-cookie-manager";
import { knowledgeHubUtils } from "./hub-utils";

import { CitationGenerator } from "./citations/CitationGenerator";
import { CitationManager } from "./citations/CitationManager";

import { SearchManager } from "./search/SearchManager";

// Sentry.io setup - this comes after other scripts are loaded
import Raven from "./raven.min";


const devMeta = document.head.querySelector("meta[name=coviumDevMode]");
if (devMeta != null && devMeta.content === "true")
{
    console.log("Initializing Knowledge Hub in DEV MODE");
    infohubInit();
}
else
{
    Raven.config('https://988207d990734ccf9c50c235dab3ff63@sentry.io/1238973').install();
    Raven.context(function () {
        infohubInit();
    });
}





// Setup globals to give access to page-level code
window.infohub["Scroller"] = Scroller;
window.infohub["ProgressiveLoader"] = ProgressiveLoader;
window.infohub["InfohubDataLoader"] = InfohubDataLoader;
window.infohub["InfohubDomUtils"] = InfohubDomUtils;

window.khub.citations = window.khub.citations || { };
window.khub.citations["CitationGenerator"] = CitationGenerator;
window.khub.citations["CitationManager"] = CitationManager;

window.khub.components = window.khub.components || { };
window.khub.components["FavoriteButton"] = FavoriteButton;
window.khub.components["FavoritesManager"] = FavoritesManager;

window.khub.controls = window.khub.controls || { };
window.khub.controls["VideoPlayer"] = VideoPlayer;

window.khub.help = window.khub.help || { };
window.khub.help.tour = window.khub.help.tour || { };
window.khub.help.tour.hopscotch = hopscotch;
window.khub.help.tour.GuidedTour = GuidedTour;

window.khub.pages = window.khub.pages || { };
window.khub.pages["PublicationsHomepage"] = PublicationsHomepage;
window.khub.pages["TopicHomepage"] = TopicHompage;
window.khub.pages["TopicsHomepage"] = TopicsHomepage;

window.khub.search = window.khub.search || { };
window.khub.search["SearchManager"] = SearchManager;

window.khub.debug = window.khub.debug || { };
window.khub.debug.cookieManager = cookieManager;

window.khub.utils = knowledgeHubUtils;

window.infohub["Range"] = Range;
window.infohub["components"] = {};
window.infohub.components["EntityRefsByPubComponent"] = EntityRefsByPubComponent;
//window.infohub.components["CitationGenerator"] = CitationGenerator;
window.$ = $;

console.log("index.js finished");

