


import { CitationFormatterBase } from "./CitationFormatterBase";


/**
 * For information on the MLA citation format, see http://www.easybib.com/guides/citation-guides/mla-format/
 */
export class CitationFormatterMla extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let result = "";

        // AUTHORS
        let authorsStr = "";
        switch (authorsList.length)
        {
            case 0:
                break;

            case 1:
                authorsStr = authorsList[0].getSurnameCommaFirstAndMiddleInitial();
                if (authorsList[0].props.role)
                {
                    authorsStr += (", " + authorsList[0].props.role.toLowerCase());
                }
                break;

            case 2:
                authorsStr = authorsList[0].getSurnameCommaFirstAndMiddleInitial();
                authorsStr += ", and ";
                authorsStr += authorsList[1].getSurnameCommaFirstAndMiddleInitial();
                if (authorsList[0].props.role)
                {
                    authorsStr += (", " + this.getRolePlural(authorsList[0].props.role).toLowerCase() );
                }
                break;

            default:
                authorsStr = authorsList[0].getSurnameCommaFirstAndMiddleInitial();
                authorsStr += " et al";
                break;
        }
        if (authorsStr != "")
        {
            result += authorsStr;
            if (!result.endsWith("."))
            {
                result += ".";
            }
            result += " ";
        }

        //
        //  BODY
        //
        if (citationObj.pubType === "book")
        {
            // TITLE, Italicized
            result += ("<i>" + citationObj.title + "</i>. ");

            // PUBLISHER
            const publisherName = citationObj.publisher.name;
            if (publisherName)
            {
                result += publisherName;
            }

            // PUBLICATION YEAR
            const pubDate = citationObj.pubYear;
            if (pubDate)
            {
                result += (", " + pubDate);
            }

            if (publisherName || pubDate)
            {
                result += ".";
            }
        }
        else // periodical
        {
            // Article title (title may not be present on issue citations)
            if (citationObj.title)
            {
                result += `"${citationObj.title}." `;
            }

            // Journal title in italics
            result += `<i>${citationObj.publishedIn.title}</i> `;

            // Volume.Issue
            result += `${citationObj.publishedIn.volume}.${citationObj.publishedIn.issue} `;

            // Year published
            result += `(${citationObj.pubYear})`;

            // Pages
            if (citationObj.publishedIn.pages)
            {
                result += `: ${citationObj.publishedIn.pages}`;
            }


            result += ". Print.";
        }




        return result;
    }
}