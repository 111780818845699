



/*
    EXAMPLE AUTHOR INFO:
           {
            "iid": "2_10819",
            "name": "Welsh, Mark A. [Welsh, Mark Anthony; prefix=Gen; suffix=III]",
            "order": 0
          }
 */


export class Author
{
    constructor(authorInfo, config)
    {
        this.init(authorInfo);
    }

    get firstInitial() { return this._firstI; }

    get firstName() { return this._first; }

    get fullName() { return this._fullName;  }

    get middleInitial() { return this._middleI; }

    get middleName() { return this._middle; }

    get nameType() { return this._nameType; }

    get surname() { return this._surname; }

    get prefix() { return this.authorProps.prefix; }

    get props() { return this.authorProps; }

    get suffix() { return this.authorProps.suffix; }


    getFirstNameSurname()
    {
        // If no first name, use FULL NAME if available, else surname only
        if (this.firstName === "")
        {
            return this.getSurnameOrFullName();
        }
        else
        {
            return `${this.firstName} ${this.surname}`;
        }
    }

    getFirstNameMiddleInitialSurname()
    {
        if (this.firstName === "")
        {
            return this.getSurnameOrFullName();
        }
        else
        {
            return `${this.firstName}${this.getOptionalMiddleInitial()} ${this.surname}`;
        }
    }

    getFirstNameMiddleInitialSurnameWithPrefix()
    {
        let result = (this.props.prefix ? this.props.prefix + " " : "");

        result += this.getFirstNameMiddleInitialSurname();

        if (this.props.suffix)
        {
            result += " ";
            result += this.props.suffix;
        }

        return result;
    }

    getOptionalMiddleInitial()
    {
        return this.middleInitial !== "" ? " " + this.middleInitial : "";
    }

    getOptionalPrefix()
    {
        return this.prefix ? this.prefix + " " : "";
    }

    getSurnameCommaFirstNameMiddleInitialWithPrefix()
    {
        if (this.firstName === "")
        {
            return this.getSurnameOrFullName();
        }
        else
        {
            return `${this.surname}, ${this.getOptionalPrefix()}${this.firstName}${this.getOptionalMiddleInitial()}`;
        }
    }

    getSurnameCommaFirstNameMiddleInitial()
    {
        // If no first name, use surname only
        if (this.firstName === "")
        {
            return this.getSurnameOrFullName();
        }
        else
        {
            return `${this.surname}, ${this.firstName}${this.getOptionalMiddleInitial()}`;
        }
    }

    getSurnameCommaFirstAndMiddleInitial()
    {
        if (this.firstInitial === "")
        {
            return this.getSurnameOrFullName();
        }
        else
        {
            return `${this.surname}, ${this.firstInitial}${this.getOptionalMiddleInitial()}`;
        }
    }

    getSurnameOrFullName()
    {
        return this.fullName !== "" ? this.fullName : this.surname;
    }


    init(authorInfo)
    {
        this._firstI = "";
        this._first = "";
        this._fullName = "";
        this._middleI = "";
        this._middle = "";
        this._surname = "";
        this._nameType = "person";

        this.authorProps = { };

        // Process Annotations
        const nameStr = this.extractNameAndProps(authorInfo.name);

        if (this.authorProps.authorType === "org")
        {
            this._nameType = "org";
            this._fullName = nameStr;
        }
        else
        {
            const commaPos = nameStr.indexOf(",");

            this._surname = nameStr.substring(0, commaPos);

            const nameRest = nameStr.substring(commaPos + 1).trim();

            const firstEnd = nameRest.indexOf(" ");

            this._first = (firstEnd < 0 ? nameRest : nameRest.substring(0, firstEnd));

            this._firstI = this.makeInitial(this._first);

            this._middle = (firstEnd < 0 ? "" : nameRest.substring(firstEnd + 1).trim());

            this._middleI = this.makeInitial(this.middleName);
        }
    }

    extractNameAndProps(srcName)
    {
        let resultName = srcName;

        const bracketIndex = srcName.indexOf("[");
        if (bracketIndex > 0)
        {
            const closeBracketIndex = srcName.indexOf("]");

            // By default, result name is value before the [ ]
            resultName = srcName.substring(0, bracketIndex).trim();


            const bracketedStr = srcName.substring(bracketIndex + 1, closeBracketIndex).trim();

            // If the bracketed string contains a name before the first semicolon, that is the name we will use for the citation
            const semicolonIndex = bracketedStr.indexOf(";");
            const citationNameStr = (semicolonIndex > 0 ? bracketedStr.substring(0, semicolonIndex).trim() : "");
            if (citationNameStr.length > 0)
            {
                resultName = citationNameStr;
            }

            if (semicolonIndex >= 0)
            {
                const propsString = bracketedStr.substring(semicolonIndex + 1);
                const nameValueStrs = propsString.split(";");

                for (let i = 0; i < nameValueStrs.length; ++i)
                {
                    const nameValueStr = nameValueStrs[i];
                    const equalsIndex = nameValueStr.indexOf("=");

                    if (equalsIndex < 0)
                    {
                        this.authorProps[nameValueStr.trim()] = true;
                    }
                    else
                    {
                        const propName = nameValueStr.substring(0, equalsIndex).trim();
                        const propVal = nameValueStr.substring(equalsIndex + 1).trim();

                        this.authorProps[propName] = propVal;
                    }
                }
            }
        }

        return resultName;
    }

    makeInitial(nameStr)
    {
        if (nameStr === "")
        {
            return "";
        }
        else if (nameStr.length == 1)
        {
            return nameStr + ".";
        }
        else if (nameStr.length == 2 && nameStr.charAt(1) === ".")
        {
            return nameStr;
        }
        else
        {
            return nameStr.charAt(0) + ".";
        }
    }

}

