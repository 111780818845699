

import $ from "jquery";

import { FavoritesManager } from "../hub-favorites-manager";
import { Scroller } from "../scroller";


export class PublicationsHomepage
{
    constructor()
    {
        this.favsMgr = new FavoritesManager();

        this.init();
    }


    init()
    {
        console.log("Initializing PublicationsHomepage...");

        // Initializes the Favorite Publications section
        this.initFavoritePubs();
    }

    initFavoritePubs()
    {
        const self = this;

        console.log("Initializing Favorite Publications...");

        const favPubs = this.favsMgr.getAllFavorites("pubs");

        console.log(`${favPubs.length} publications in favorites`);



        if (favPubs && favPubs.length > 0)
        {
            const scrollerDiv = $("#favPubsScrollerDiv");
            const itemsDiv = $(scrollerDiv).find(".items");

            for (let i = 0; i < 5 && i < favPubs.length; ++i)
            {
                $(itemsDiv).append(this.makeScrollerItemDiv(favPubs[i]));
            }

            $("#noFavsMsg").hide();
            $(scrollerDiv).show();

            // Initialize the Scroller
            this.scroller = new Scroller("favPubsScrollerDiv", {
                blocksPreloaded: 1,
                itemsPerBlock: 5,
                itemsPerColumn: 1,
                totalItems: favPubs.length,
                blockDataLoader: function(blockNum, successCallback, errorCallback, scrlr) {
                    successCallback(self.favsMgr.getAllFavorites("pubs"));
                },
                populateColumn: function(columnDiv, columnNumber, blockNumber, blockData, scrlr) {
                    console.log(`Populating column ${columnNumber}, block ${blockNumber}`);
                    $(columnDiv).append(self.makeScrollerItemDiv(blockData[columnNumber]));
                }
            });


        }
    }


    makeScrollerItemDiv(favPubObj)
    {
        console.log(`Favorite: ${JSON.stringify(favPubObj)}`);

        const pubIid = favPubObj.pubIid;
        const title = favPubObj.title;
        const thumbImgUrl = favPubObj.thumbUrl;
        const pubPgUrl = favPubObj.pubPgUrl;

        const itemHtml = `<a href="${pubPgUrl}"><img class="cover-img img-fluid mx-auto d-block" src="${thumbImgUrl}" /></a>
<div class="text-center" style="width:100%;">
  <a href="${pubPgUrl}">${title}</a>
</div>`;

        return $("<div/>", { class: "item" }).html(itemHtml);
    }


}