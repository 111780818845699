


import $ from "jquery";
import "./component-favorite-button.css";


export class FavoriteButton
{
    constructor(elemId, config)
    {
        this.elemId = elemId;
        this.elemSelector = "#" + elemId;
        this.checkboxId = this.elemId + "_chkbx";

        this.config = config || { };
        this.toggleHandler = config.toggleHandler || function() { };

        this.init();
    }

    init()
    {
        const self = this;
        const initialState = this.config.initialState;

        const html =
        `<label for="${this.checkboxId}" class="fav-btn ${this.config.class || ''}" data-toggle="tooltip" data-placement="top" title="${this.getTooptipText(initialState)}">
            <input id="${this.checkboxId}" type="checkbox" />
            <i class="fa fa-star-o"></i>
            <i class="fa fa-star"></i>
            <span></span>
        </label>`;

        $(this.elemSelector).html(html);

        this.labelElem = $(this.elemSelector + " label");
        this.checkBox = $(this.elemSelector + " input");

        if (initialState)
        {
            $(this.checkBox).prop("checked", true);
        }

        // Initialize tooltip
        $(this.labelElem).tooltip();

        $(this.checkBox).change(function() {
            const state = $(self.checkBox).prop("checked");
            console.log(`Checkbox state changed to: ${state}`);

            // Update tooltip
            $(self.labelElem).attr("data-original-title", self.getTooptipText(state)).tooltip("hide").tooltip("show");

            // Invoke toggle handler
            self.toggleHandler(state, self);
        });
    }



    getTooptipText(state)
    {
        return (state ? "Remove from Favorites" : "Add to Favorites");
    }
}