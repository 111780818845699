

import { Author } from "./Author";

import { CitationFormatterApa } from "./CitationFormatterApa";
import { CitationFormatterAupNote } from "./CitationFormatterAupNote";
import { CitationFormatterAupNoteShort } from "./CitationFormatterAupNoteShort";
import { CitationFormatterAupBiblio } from "./CitationFormatterAupBiblio";
import { CitationFormatterMla } from "./CitationFormatterMla";
import {CitationFormatterChicago} from "./CitationFormatterChicago";


/*
     SAMPLE CITATION OBJECT:

     {
        "title": "Some Reflections on the Intersection of Law and Ethics in Cyber War",
        "authors": [
          {
            "iid": "2_10819",
            "name": "Dunlap, Charles J. [Gen Mark A. Welsh III]",
            "order": 0
          }
        ],
        "date": "2013",
        "publisher": {
          "iid": "2_40586",
          "name": "Air University Press",
          "loc": "Maxwell Air Force Base"
        },
        "publishedIn": {
          "title": "Air and Space Power Journal",
          "volume": "29",
          "issue": "3",
          "date": "May-June 2015",
          "pages": "3-9",
          "type": "periodical"
        }
     }
 */


export class CitationGenerator
{
    constructor()
    {
        this.formatters = {
            "aup": new CitationFormatterAupNote(),
            "aupShort": new CitationFormatterAupNoteShort(),
            "aupBiblio": new CitationFormatterAupBiblio(),
            "apa": new CitationFormatterApa(),
            "chicago": new CitationFormatterChicago(),
            "mla": new CitationFormatterMla()
        };
    }

    generateCitation(citationSrc, formatList, config)
    {
        const citationData = {
            src: citationSrc,
            title: citationSrc.metadata.title,
            pubYear: citationSrc.metadata.date,
            publisher: citationSrc.metadata.publisher,
            publishedIn: citationSrc.metadata.publishedIn && citationSrc.metadata.publishedIn.title && citationSrc.metadata.publishedIn.title !== "" ? citationSrc.metadata.publishedIn : null,
            authors: this.createSortedAuthorsList(citationSrc.metadata),
            pubType: this.getPublicationType(citationSrc.metadata, config),
        };

        if (citationSrc.pos)
        {
            citationData.pos = citationSrc.pos;
        }

        let result = { };

        for (let formatId of formatList)
        {
            const formatter = this.formatters[formatId];

            result[formatId] = formatter.generateCitation(citationData, config);
        }

        return result;
    }

    getPublicationType(citationSrc, config)
    {
        if (citationSrc.publishedIn && citationSrc.publishedIn.title)
        {
            return "periodical";
        }
        else
        {
            return "book";
        }
    }

    createSortedAuthorsList(citationSrc)
    {
        const result = [ ];

        for (let authorSrc of citationSrc.authors)
        {
            result.push(new Author(authorSrc, {}))
        }

        return result;
    }
}