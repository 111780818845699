

import $ from "jquery";
require("./jquery.twinkle-0.8.0");
import * as hopscotch from "./hopscotch";

import { Config } from "./infohub-setup";
import { ModalDialog } from "./component-dialog";
import { knowledgeHubUtils } from "./hub-utils";

const section1Id = "tourSection1";
const section2Id = "tourSection2";
const section3Id = "tourSection3";
const section4Id = "tourSection4";


class TourSection
{
    constructor(sectionId, tourObj, config)
    {
        const self = this;
        this.sectionId = sectionId;
        this.tour = tourObj;
        this.config = config;
        this.startDialogSubmitHandler = config.startDialogSubmitHandler || function() { self.tour.advanceToFirstStep(self.sectionId); };
    }

    start()
    {
        const self = this;

        const modal = new ModalDialog(`${this.sectionId}StartModal`, {
            title: "Knowledge Hub Guided Tour",
            body: this.config.startDialogBody,
            submitButtonLabel: "Begin",
            submitHandler: function() {
                console.log(`Starting ${self.sectionId}...`);
                self.startDialogSubmitHandler();  //self.tour.advanceToFirstStep(self.sectionId);
            }
        });

        modal.show();
    }

    finish()
    {
        const self = this;

        console.log(`Finishing section ${this.sectionId}`);

        const modal = new ModalDialog(`${this.sectionId}EndModal`, {
            title: "Knowledge Hub Guided Tour",
            body: self.config.finishDialogBody,
            submitButtonLabel: "Continue",
            submitHandler: function() {
                console.log(`Continuing to section ${self.config.nextSectionId}`);
                if (self.config.nextSectionId) {
                    self.tour.beginTourSection(self.config.nextSectionId);
                }
            }
        });

        modal.show();

        knowledgeHubUtils.event("tourSecFinished", [ self.sectionId ]);
    }

    createTourConfig()
    {
        const self = this;
        return {
            id: self.sectionId,
            showPrevButton: true,
            bubbleWidth: 350,
            onNext: function() {
                knowledgeHubUtils.event("tourStep", [ self.sectionId, hopscotch.getCurrStepNum() ]);
            },
            onClose: function() {
                knowledgeHubUtils.event("tourClosed");
            },
            onEnd: function() {
                console.log("Ended tour section 1");
                self.tour.finishTourSection(self.sectionId);
            },
            steps: self.config.steps()
        };
    }
}







export class GuidedTour
{
    constructor()
    {
        const self = this;

        this.tourConfigs = { };
        this.sections = { };

        this.sections[section1Id] = new TourSection(section1Id, this, {
            nextSectionId: section2Id,
            startDialogBody: `<h3>Section 1: Overview and Navigation</h3>
<p>Section 1 of the guided tour will give you an overview of the Knowledge Hub and will show you how
to navigate through its different pages.</p>
<p>To start the tour, press the 'Begin' button below.</p>`,
            finishDialogBody: `<h3>Section 1: Overview and Navigation - Completed</h3>
<p>You have completed Section 1 of the guided tour, which gave you a brief overview of the Knowledge Hub and its navigation menu.</p>
<p>You can click on the 'Continue' button below to continue on to Section 2 of the tour, which 
will introduce you to Knowledge Hub Topics and Topic-related pages.</p>
<p>If you chose to exit the guided tour by pressing the 'Cancel' button, you can resume it at any time in the future by going to the 'Help' page.</p>`,
            steps: function() {
                return [
                    {
                        title: "Navigation Menu Bar",
                        content: "Each Knowledge Hub page includes a navigation menu bar (with a dark blue background) that contains links that let you quickly navigate to the main sections of the Knowledge Hub.  We'll walk you through the items in the navigation bar next.",
                        target: "nav",
                        placement: "bottom"
                    },
                    {
                        title: "Knowledge Hub Homepage Link",
                        content: "You can click on the 'AUP Knowledge Hub' link to go to the Knowledge Hub's homepage.",
                        target: document.querySelector(".navbar-brand"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction(".navbar-brand")
                    },
                    {
                        title: "Topics/Subject Areas/Publications",
                        content: "These links take you to the main Topic, Subject Area, and Publication pages.  We'll cover those pages in more detail during subsequent sections of the tour.",
                        target: document.querySelector("#menuPubs"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuTopics, #menuTx_Subject_Areas, #menuPubs")
                    },
                    {
                        title: "Frequently Asked Questions (FAQ) Link",
                        content: "The FAQ link will take you to the Frequently Asked Questions page where you can see answers to commonly asked questions about the Knowledge Hub and submit your own questions",
                        target: document.querySelector("#menuFaq"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuFaq")
                    },
                    {
                        title: "Help Link",
                        content: "If you have questions about how to use the Knowledge Hub or need assistance, click on 'Help' link in the navigation menu bar to go to the Help page. You can also resume or restart the guided tour from the Help page.",
                        target: document.querySelector("#menuHelp"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuHelp")
                    },
                    {
                        title: "Air University Press Hompage Link",
                        content: "This link will take you from the Knowledge Hub to the Air University Press's main webpage, where you can get additional information on the Air University Press and its publications.",
                        target: document.querySelector("#menuAupHomepage"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuAupHomepage")
                    },
                    {
                        title: "Comments and Suggestions Link",
                        content: "If you would like to leave a comment about the Knowledge Hub or would like to suggest improvements, click on this button to go to the Comments and Suggestions page.  We welcome and encourage all feedback that you may have!",
                        target: document.querySelector("#menuCommentBtn"),
                        placement: "left",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuCommentBtn")
                    },
                    {
                        title: "Breadcrumb Bar",
                        content: "The 'breadcrumb' bar has a light gray background and is located immediately below the navigation menu bar.  It shows you the location of the current page within the overall structure of the Knowledge Hub.  You can click on any of the individual links in the breadcrumb to easily go to nearby pages.",
                        target: document.querySelector("ol.breadcrumb"),
                        placement: "bottom"
                    }
                ]
            }
        });


        this.sections[section2Id] = new TourSection(section2Id, this, {
            nextSectionId: section3Id,
            startDialogBody: `<h3>Section 2: Topics</h3>
<p>This section of the guided tour will introduce you to Topics and topic-related pages.  <i>Topics</i> are the people, places, things, and concepts that the Knowledge Hub deals with.</p>
<p>We will begin on the Topics page, which you will be taken to automatically after pressing the 'Begin' button.</p>`,
            startDialogSubmitHandler: function() {
                window.location = `${Config.baseUrl}topics/home?autoStart=tour2`;
            },
            finishDialogBody: `<h3>Section 2: Topics - Completed</h3>
<p>You have now completed Section 2 of the Knowledge Hub guided tour, which introduced you to Topics and topic-related pages.
You can repeat this section of the guided tour at any time by clicking on the Help menu in the navigation bar.</p>
<p>You can click on the 'Continue' button below to continue on to Section 3 of the tour, which will introduce you
to Publications and publication-related pages in the Knowledge Hub.</p>
<p>If you chose to exit the guided tour by pressing the 'Cancel' button, you can resume it at any time in the future by going to the 'Help' page.</p>`,
            steps: function() {
                return [
                    {
                        title: "Topics Page",
                        content: "This is the Knowledge Hub's main Topics page, which you can reach by clicking on 'Topics' in the navigation menu.",
                        target: document.querySelector("#menuTopics"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuTopics")
                    },
                    {
                        title: "Favorite Topics List",
                        content: "This is a list of topics that you have marked as favorites.  You can use the favorites list to keep track of topics that you're interested in and reference frequently.",
                        target: document.querySelector("#favTopicsTitle"),
                        placement: "top",
                        xOffset: 30
                    },
                    {
                        title: "All Topics List",
                        content: "This is a list of all of the topics available in the Knowledge Hub.  You can browse through the list to find topics of interest and get a feel for what the Knowledge Hub covers.",
                        target: document.querySelector("#allTopicsTitle"),
                        placement: "top",
                        xOffset: 30,
                        onShow: self.createOnShowFunction("#allTopicsTitle")
                    },
                    {
                        title: "Browse Order Selector",
                        content: "By default the topic list is ordered alphabetically by the topic name, but you can select other sort orders here.",
                        target: document.querySelector("#dropdownMenuButton"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#dropdownMenuButton")
                    },
                    {
                        title: "Scroll Buttons",
                        content: "You can scroll to a specific section of the topics list using any of these buttons.  The buttons that correspond to the section of the list that you're currently looking at will be highlighted in gray.",
                        target: document.querySelector(".pager-buttons"),
                        placement: "top",
                        onShow: self.createOnShowFunction(".pager-buttons:first-of-type")
                    },
                    {
                        title: "Scroll Back/Forward Buttons",
                        content: "The back and forward buttons on either side of the topics list allow you to scroll backwards and forwards through the topics list",
                        target: document.querySelector("#browseDiv .next-btn"),
                        placement: "left",
                        yOffset: -5,
                        onShow: self.createOnShowFunction("#browseDiv .next-btn")
                    },
                    {
                        title: "Suggest Topic Link",
                        content: "If you're interested in a topic that isn't in the Topics list, you can use this link to suggest that the topic be added to the Knowledge Hub",
                        target: document.querySelector("#suggestTopicLink"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#suggestTopicLink")
                    },
                    {
                        title: "Topic Reference Count",
                        content: "Each topic in this list includes a reference count that indicates how many times the topic was referenced across all publications in the Knowledge Hub.  You can sort the topics based on reference count using the browse order selector.",
                        target: document.querySelector("#browseDiv .index-row .badge-pill"),
                        placement: "top",
                        xOffset: -15,
                        onShow: self.createOnShowFunction("#browseDiv .index-row:first-of-type .media:first-of-type .badge-pill")
                    },
                    {
                        title: "Topic Link",
                        content: "You can click on any topic to go to a page with additional information about the topic.  We will visit one of these pages in the next stop on the tour.  You will automatically be taken to a new page after hitting the 'Next' button.",
                        target: document.querySelector("#browseDiv .item .media-body a"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#browseDiv .item:first-of-type .media:first-of-type .media-body a"),
                        multipage: true,
                        onNext: function () {
                            window.location = `${Config.baseUrl}entity/mitchell_william_billy`
                        }
                    },
                    {
                        title: "Topic Name",
                        content: "This is the topic details page for William 'Billy' Mitchell.  Each topic in the Knowledge Hub has a similar page.  The topic name appears at the top of the page.",
                        target: document.querySelector("h1"),
                        placement: "top"
                    },
                    {
                        title: "Favorites Button",
                        content: "You can add and remove a topic from your favorites list by clicking on the star button.",
                        target: document.querySelector("#topicFavBtn"),
                        placement: "left",
                        yOffset: -5
                    },
                    {
                        title: "References Section",
                        content: "The References section allows you to see where and how the topic was referenced throughout the publications that are included in the Knowledge Hub.",
                        target: document.querySelector("h3"),
                        placement: "top"
                    },
                    {
                        title: "Total Reference Count",
                        content: "This shows you the total number of references to the topic across all publications",
                        target: document.querySelector("#totalRefsAllPubsSpan"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#totalRefsAllPubsSpan")
                    },
                    {
                        title: "Number of Referencing Publications",
                        content: "And this shows you the total number of publications that contain references to the topic",
                        target: document.querySelector("#totalPubsCountSpan"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#totalPubsCountSpan")
                    },
                    {
                        title: "Referencing Publication",
                        content: "Each publication that references the topic has a section that allow you to explore the references within that publication.",
                        target: document.querySelector("#pub1 .cover-img"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#pub1 .cover-img")
                    },
                    {
                        title: "Number of References in Publication",
                        content: "This shows the total number of references to the topic within the publication.",
                        target: document.querySelector("#pub1 .totalRefsInPubSpan"),
                        placement: "bottom",
                        onShow: self.createOnShowFunction("#pub1 .totalRefsInPubSpan")
                    },
                    {
                        title: "Reference Distribution",
                        content: "This gives you a measure of how the references to the topic are distributed throughout the publication.  A number closer to 1 indicates that there are many references to the topic throughout the publication, while a value closer to 0 indicates fewer references that are clustered in different areas.",
                        target: document.querySelector(".pub-list-entry .media-body .text-hi:nth-of-type(2)"),
                        placement: "bottom",
                        onShow: self.createOnShowFunction("#pub1 .refsDistSpan")
                    },
                    {
                        title: "Reference Frequency Sparkline",
                        content: "This gives a visual indication of where references are located throughout the publication, with peaks indicating a large number of references.  You can move your cursor over the graph to see what page range each point corresponds to and how many references there are to the topic within that page range.",
                        target: document.querySelector(".pub-list-entry:first-of-type .sparkline"),
                        placement: "bottom",
                        onShow: self.createOnShowFunction("#pub1 .sparkline"),
                        onNext: function () {
                            entRefsByPubComp.showRefs($(".showrefs-link:first"));
                        }
                    },
                    {
                        title: "Reference Excerpt",
                        content: "For each reference to the topic, an excerpt is provided that shows the context of the reference. References to the topic are highlighted in <span style='background-color: #f3e97a;'>yellow</span>.  Other topics that appear in the excerpt have hyperlinks that will take you to the details page for the topic.",
                        target: document.querySelector("#pub1 .snippet1"),
                        placement: "top"
                    },
                    {
                        title: "Read-It-Now Link",
                        content: "Each excerpt includes a link that will open the relevant publication in your browser and will take you to the page that includes the reference.",
                        target: document.querySelector("#pub1 .snippet1 .fa-external-link"),
                        placement: "top",
                        xOffset: -35,
                        onShow: self.createOnShowFunction("#pub1 .snippet1 .fa-external-link")
                    },
                    {
                        title: "Citation Generator",
                        content: "You can generate a citation for the reference by clicking on the 'Cite' button.  The citation is provided in MLA, APA, and Chicago formats.",
                        target: document.querySelector("#pub1 .snippet1 .citation-btn"),
                        placement: "top",
                        xOffset: -10,
                        onShow: self.createOnShowFunction("#pub1 .snippet1 .citation-btn")
                    },
                    {
                        title: "References Range Selector",
                        content: "When there are more references in a publication than we can initially display, you can use this range selector to show more references, or to show only the references in a specified page range",
                        target: document.querySelector("#pub1 .ref-range-sel"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#pub1 .ref-range-sel")
                    }
                ]
            }
        });

        this.sections[section3Id] = new TourSection(section3Id, this, {
            nextSectionId: section4Id,
            startDialogBody: `<h3>Section 3: Publications</h3>
<p>Section 3 of the Knowledge Hub guided tour will introduce you to Publications and publication-related pages.</p>
<p>This section of the tour will begin on the Knowledge Hub's main Publications page, which you will be taken to automatically after hitting the 'Begin' button.</p>`,
            startDialogSubmitHandler: function() {
                window.location = `${Config.baseUrl}publications/home?autoStart=tour3`;
            },
            finishDialogBody: `<h3>Section 3: Publications - Completed</h3>
<p>You have now completed Section 3 of the Knowledge Hub guided tour, which introduced you to Publications and publication-related pages.
You can repeat this section of the guided tour at any time by clicking on the Help menu in the navigation bar.</p>
<p>You can click on the 'Continue' button below to continue on to Section 4 of the tour, which will introduce
you to Subject Areas.</p>
<p>If you chose to exit the guided tour by pressing the 'Cancel' button, you can resume it at any time in the future by going to the 'Help' page.</p>`,
            steps: function() {
                return [
                    {
                        title: "Publications Page",
                        content: "This is the Knowledge Hub's main Publications page, which you can reach by clicking on 'Publications' in the navigation menu.",
                        target: document.querySelector("#menuPubs"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuPubs")
                    },
                    {
                        title: "Favorite Publications List",
                        content: "This is a list of publications that you have marked as favorites.  You can use the favorites list to keep track of publications that you're interested in and reference frequently.",
                        target: document.querySelector("#favPubsTitle"),
                        placement: "top",
                        xOffset: 30
                    },
                    {
                        title: "All Publications List",
                        content: "This list shows all of the publications that are included in the Knowledge Hub.  You can scroll through this list to find publications of interest.",
                        target: document.querySelector("#allPubsTitle"),
                        placement: "top"
                    },
                    {
                        title: "Browse Order Selector",
                        content: "By default publications in the list are grouped first by Subject Area, and then alphabetically by title within each area.  You can use this selector to change the ordering to alphabetical by title",
                        target: document.querySelector("#dropdownMenuButton"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#dropdownMenuButton")
                    },
                    {
                        title: "Scroll Buttons",
                        content: "You can jump to specific sections of the publications list using any of these buttons.  The buttons that correspond to the section of the list you're currently viewing are highlighted in gray.",
                        target: document.querySelector("#browseDiv .pager-buttons"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#browseDiv .pager-buttons button")
                    },
                    {
                        title: "Scroll Back/Forward Buttons",
                        content: "You can scroll backwards and forwards through the publications list using the scroll buttons on either side of the list",
                        target: document.querySelector("#browseDiv .next-btn"),
                        placement: "left",
                        onShow: self.createOnShowFunction("#browseDiv .prev-btn, .next-btn")
                    },
                    {
                        title: "Publication Details Link",
                        content: "Clicking on any of the publications in the list will take you to the details page for the publication.  We will visit one of the publication details pages on the next stop of the tour.  You will automatically be taken to the details page after hitting the 'Next' button.",
                        target: document.querySelector("#browseDiv .wrapper > div > div:first-of-type"),
                        placement: "bottom",
                        onShow: self.createOnShowFunction("#browseDiv .wrapper > div > div:first-of-type"),
                        multipage: true,
                        onNext: function () {
                            const pageUrl = $("#browseDiv .item").first().find("a").first().attr("href");
                            console.log(`Continuing to page ${pageUrl}`);
                            window.location = pageUrl;
                        }
                    },
                    {
                        title: "Publication Details Page",
                        content: "Each publication included in the Knowledge Hub has a details page like this one.  The publication's title is listed at the top of the page.",
                        target: document.querySelector("h1"),
                        placement: "bottom"
                    },
                    {
                        title: "Favorites Button",
                        content: "You can add and remove a publication from your favorites list by clicking on the star button.",
                        target: document.querySelector("#pubFavBtn"),
                        placement: "left",
                        yOffset: -5
                    },
                    {
                        title: "Bibliographic Information",
                        content: "The right-hand sidebar contains bibliographical information about the publication.",
                        target: document.querySelector(".card"),
                        placement: "left"
                    },
                    {
                        title: "Citation Generator",
                        content: "You can generate a citation for the publication using this button. The citation is provided in MLA, APA, and Chicago formats.",
                        target: document.querySelector("#citeBtn"),
                        yOffset: -10,
                        placement: "left"

                    },
                    {
                        title: "Read Button",
                        content: "This button allows you to read the publication directly in your browser, enhanced with topic links throughout the text.  Clicking on any of the topic links in the publication will take you to the corresponding Knowledge Hub topic details page.",
                        target: document.querySelector("#readButton"),
                        placement: "top"

                    },
                    {
                        title: "Download Button",
                        content: "You can also download the publication for off-line reading.  The downloaded version includes topic links that will take you to Knowledge Hub topic pages (although it may require exiting your PDF reader application and opening the topic page in a web browser).",
                        target: document.querySelector("#downloadButton"),
                        placement: "top"

                    },
                    {
                        title: "Most Referenced Topics List",
                        content: "This list shows the topic referenced in the publication, ordered from most-referenced to least-referenced.  There is also an alphabetical topic list below.",
                        target: document.querySelector(".container h3:first-of-type"),
                        placement: "top"
                    },
                    {
                        title: "Topics",
                        content: "Each topic in the list includes a blue bubble that shows the total number of references to that topic in the publication.",
                        target: document.querySelector("#mrtScroller .items div:first-of-type li:first-of-type a"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#mrtScroller .items div:first-of-type li:first-of-type a")
                    },
                    {
                        title: "Topic Reference Details",
                        content: "Clicking on any of the topics in the list will take you to a page that details all of the references to that topic in the publication,  We will examine one of those pages in the next stop on the tour.  You will automatically be taken to the new page after hitting the Next' button.",
                        target: document.querySelector("#mrtScroller .items div:first-of-type li:first-of-type a"),
                        placement: "top",
                        onShow: self.createOnShowFunction("#mrtScroller .items div:first-of-type li:first-of-type a"),
                        multipage: true,
                        onNext: function () {
                            const pageUrl = $("#mrtScroller").find("li").first().find("a").first().attr("href");
                            console.log(`Continuing to page ${pageUrl}`);
                            window.location = pageUrl;
                        }
                    },
                    {
                        title: "Topic Reference Details",
                        content: "This page gives you a detailed view of all references to this topic within the single publication whose title is listed at the top of the page.",
                        target: document.querySelector(".container div:first-of-type h3:first-of-type"),
                        placement: "bottom"
                    },
                    {
                        title: "Total Number of References",
                        content: "This shows you the total number of references to the topic within the publication",
                        target: document.querySelector("#totalRefsSpan"),
                        placement: "right",
                        yOffset: -25,
                        onShow: self.createOnShowFunction("#totalRefsSpan")
                    },
                    {
                        title: "Reference Distribution",
                        content: "And this shows you a measure of the distribution of references to the topic within the publication",
                        target: document.querySelector("#refDistSpan"),
                        placement: "right",
                        yOffset: -25,
                        onShow: self.createOnShowFunction("#refDistSpan")
                    },
                    {
                        title: "Comparison with Other Publications",
                        content: "Here you can see how the quantity of references to the topic in the publication compares to other publications.  Clicking on the link takes you to a detailed comparison of all of the referencing publications.",
                        target: document.querySelector("#referencingPubsSummaryLink"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#referencingPubsSummaryLink")
                    },
                    {
                        title: "Reference Distribution Sparkline",
                        content: "This gives you a graphical illustration of how references to the topic are distributed throughout the publication.  Peaks indicate a higher number of references.",
                        target: document.querySelector("#refdistspkln"),
                        placement: "bottom",
                        yOffset: 10,
                        onShow: self.createOnShowFunction("#refdistspkln")
                    },
                    {
                        title: "References",
                        content: "In this section you can your reference excerpts, similar to the ones we've already seen on the tour, including read-it-now and citation generation links.",
                        target: document.querySelector("#refsList"),
                        placement: "top"
                    }
                ]
            }

        });

        this.sections[section4Id] = new TourSection(section4Id, this, {
            startDialogBody: `<h3>Section 4: Subject Areas</h3>
<p>Section 4 of the Knowledge Hub guided tour will introduce you to Subject Areas.  Subject Areas are categories used to organize knowledge.</p>
<p>This section of the tour will begin on the Subject Areas page, which you will be taken to automatically once the tour begins.</p>`,
            startDialogSubmitHandler: function() {
                window.location = `${Config.baseUrl}subject_areas/home?autoStart=tour4`;
            },
            finishDialogBody: `<h3>Section 4: Subject Areas - Completed</h3>
<p>This completes the last section of the Knowledge Hub guided tour, which introduced you to Subject Areas.
You can repeat this section of the guided tour or any other section at any time by clicking on the Help menu in the navigation bar.</p>
<p>If you have questions, comments, or suggetions on this guided tour or the Knowledge Hub itself, please contact us via the
Comments link in the navigation menu.</p>`,
            steps: function() {
                return [
                    {
                        title: "Subject Areas Page",
                        content: "This is the Knowledge Hub's main Subject Areas page, which you can reach by clicking on 'Subject Areas' in the navigation menu.",
                        target: document.querySelector("#menuTx_Subject_Areas"),
                        placement: "right",
                        yOffset: -15,
                        onShow: self.createOnShowFunction("#menuTx_Subject_Areas")
                    },
                    {
                        title: "Section Links",
                        content: "Clicking on any one of these buttons will automatically scroll this page to the section for the corresponding Subject Area",
                        target: document.querySelector("#catPagerBtns"),
                        placement: "bottom",
                        onShow: self.createOnShowFunction("#catPagerBtns")
                    },
                    {
                        title: "Subject Area Section",
                        content: "Each section lists topics and publications to a given Subject Area",
                        target: document.querySelector("#catSection1 h3"),
                        placement: "bottom"
                    },
                    {
                        title: "Topics List",
                        content: "This is a scrollable list of topics that are referenced in publications that are included in the Subject Area. Each topic includes a count of how many times the topic is referenced within subject area publications.",
                        target: document.querySelector("#catSection1 div:first-of-type .scroller"),
                        placement: "top"
                    },
                    {
                        title: "Publications List",
                        content: "This is a scrollable list of all publications that are included in the Subject Area.  Clicking on a publication will take you to the details page for that publication",
                        target: document.querySelector("#catSection1 div:nth-of-type(2) .scroller"),
                        placement: "top"
                    }
                ]
            }

        });


    }


    beginTour()
    {
        console.log("Starting Guided Tour...");

        const self = this;

        const dialogBody = `<h3>Welcome!</h3>
 <p>The Knowledge Hub guided tour will introduce you to the organization and use of the Knowledge Hub.
 The tour is divided into sections, each of which focuses on a particular aspect of the Knowledge Hub.</p>
 <p>A series of explanatory prompt bubbles will appear, highlighting and explaining various items on the page.
 Click on the 'Next' button in the bubble to advance through the tour.</p>
 <p>You can exit the tour at any time.  To resume the tour or to advance to a different section, visit
 the Help page by clicking on the 'Help' item in the navigation bar at the top of each page.</p>`;

        const modal = new ModalDialog("tourSection0PromptModal", {
             title: "Knowledge Hub Guided Tour",
             body: dialogBody,
             submitButtonLabel: "Begin",
             submitHandler: function() {
                 console.log("Continuing to Section 1 of the Guided Tour");
                 self.beginTourSection(section1Id);
             }
        });

        modal.show();

        knowledgeHubUtils.event("tourStart");
    }

    beginTourSection(sectionId)
    {
        knowledgeHubUtils.event("tourStartSec", [ sectionId ]);

        this.sections[sectionId].start();

        /*
        if (sectionId === "tourSection1")
        {
            this.tourSection1Prompt();
        }
        else if (sectionId === "tourSection2")
        {
            this.tourSection2Prompt();
        }
        else if (sectionId === "tourSection3")
        {
            this.tourSection3Prompt();
        }
        else if (sectionId === "tourSection4")
        {
            this.tourSection4Prompt();
        }
        */
    }

    finishTourSection(sectionId)
    {
        this.sections[sectionId].finish();
    }

    advanceToFirstStep(sectionId)
    {
        console.log(`Beginning tour section ${sectionId}...`);

        // Start the tour!
        hopscotch.startTour(this.getOrCreateTourConfig(sectionId));
    }

    getOrCreateTourConfig(sectionId)
    {
        if (typeof this.tourConfigs[sectionId] === "undefined")
        {
            this.tourConfigs[sectionId] = this.sections[sectionId].createTourConfig();
        }
        return this.tourConfigs[sectionId];
    }


    resumeTourIfActive()
    {
        const currentState = hopscotch.getState();
        if (currentState && currentState.indexOf(":") > 0)
        {
            const tourId = currentState.substring(0, currentState.indexOf(":"));
            console.log(`Resuming tour ${tourId}`);
            hopscotch.startTour(this.getOrCreateTourConfig(tourId));
        }
    }

    createOnShowFunction(elemSelector)
    {
        return function() {
            console.log(`Twinlking ${elemSelector}`);
            $(elemSelector).twinkle({
                effect: "drops",
                effectOptions: {
                    color: "rgba(0,255,0,0.5)",
                    radius: 50,
                    duration: 2500,
                    count: 3,
                    width: 3
                }
            });
        };
    }

}

