

import { KnowledgeHubConfig } from "./hub-config";


/**
 *   We assume that the InfoHub base URL is stored in a <meta> tag in the HTML, and we use that to set the base
 *   resource URL that WebPack uses for all resources (*.js, *.css, images, fonts, etc).
 *
 *   See https://webpack.js.org/guides/public-path/ for information on setting the WebPack Public Path on-the-fly
 */

const baseUrlMeta = document.head.querySelector("meta[name=infohubBaseUrl]");
const resourcesPathMeta = document.head.querySelector("meta[name=hubRsrcPath]");
const baseUrl = (typeof baseUrlMeta === "undefined" || baseUrlMeta == null ? "/" : baseUrlMeta.content);
const resourcesPath = (typeof resourcesPathMeta === "undefined" || resourcesPathMeta == null ? "resources/" : resourcesPathMeta.content);

const dataVerMeta = document.head.querySelector("meta[name=hubDataVer]");
const dataVersionStr = (typeof  dataVerMeta === "undefined" || dataVerMeta == null ? "0" : dataVerMeta.content);


export const Config = new KnowledgeHubConfig(baseUrl, dataVersionStr);

//import * as Cookies from "./js.cookie";






console.log(`Infohub baseUrl: ${baseUrl}, resourcesPath: ${resourcesPath}`);

// Export Config in global infohub object as well
window.khub = window.khub || { };
window.khub.config = Config;

window.infohub = {};
window.infohub.Config = window.khub.config;


// // Export base URL in the Config object
// Config.baseUrl = baseUrl;
//
// Config.resourceUrl = function(rsrcPath) {
//     return Config.baseUrl + "resources/" + rsrcPath;
// };


// Set WebPack Public Path
console.log(`Setting __webpack_public_path__ to '${baseUrl}${resourcesPath}'`);
__webpack_public_path__ = baseUrl + resourcesPath;

