

console.log("range.js entered...");

export class Range {

    constructor(rangeStrOrObj)
    {
        if (rangeStrOrObj instanceof Range)
        {
            this._lower = rangeStrOrObj.start();
            this._upper = rangeStrOrObj.end();
        }
        else // Assume it is a string
        {
            let dashPos = rangeStrOrObj.indexOf('-');

            if (dashPos < 0)
            {
                this._lower = parseInt(rangeStrOrObj);
                this._upper = this._lower;
            }
            else
            {
                this._lower = parseInt(rangeStrOrObj.substring(0, dashPos));
                this._upper = parseInt(rangeStrOrObj.substring(dashPos + 1));
            }
        }
    }

    get lowerEndpoint() { return this._lower; }
    get upperEndpoint() { return this._upper; }


    contains(intVal)
    {
        return (intVal >= this.lowerEndpoint && intVal <= this.upperEndpoint);
    }

    intersects(otherRange)
    {
        if (otherRange instanceof Range)
        {
            return this.lowerEndpoint <= otherRange.upperEndpoint && this.upperEndpoint >= otherRange.lowerEndpoint;
        }
        else
        {
            throw "Invalid argument is not a range";
        }
    }

    toString()
    {
        if (this.lowerEndpoint < this.upperEndpoint)
        {
            return this.lowerEndpoint + "-" + this.upperEndpoint;
        }
        else
        {
            return this.lowerEndpoint + "";
        }
    }
}
