





export class CitationFormatterBase
{
    constructor()
    {

    }

    generateCitation(citationObj, config)
    {
    }


    getAbbreviatedRole(roleStr)
    {
        if (roleStr.toUpperCase() === "EDITOR")
        {
            return "Ed.";
        }
        else
        {
            return roleStr;
        }
    }

    getAbbreviatedRolePlural(roleStr)
    {
        if (roleStr.toUpperCase() === "EDITOR")
        {
            return "Eds.";
        }
        else
        {
            return roleStr;
        }
    }

    getRolePlural(roleStr)
    {
        return roleStr + "s";
    }

}