

import * as Cookies from "./js.cookie";

import { Config } from "./infohub-setup";

class CookieManager
{
    constructor()
    {
    }

    initializeCookies()
    {
        let firstTimeVisitor = false;

        if (Modernizr.cookies)
        {
            const cks = Cookies.get();
            const now = new Date().getTime();

            // Session cookies
            if (!("s" in cks) || ("sl" in cks && parseInt(cks.sl,16) + 3600000 < now))
            {
                const nowHexStr = now.toString(16);
                Cookies.set("s", nowHexStr);
                Cookies.set("sr", 1);
                Cookies.set("sl", nowHexStr);
                Cookies.set("so", window.location.href);
            }
            else
            {
                const newR = parseInt(cks.sr, 16) + 1;
                console.log(`Updating session cookies, sr=${cks.sr}, sl=${cks.sl}, newR=${newR}`);
                Cookies.set("sr", newR.toString(16));
                Cookies.set("sl", now.toString(16));
                Cookies.remove("so");
            }

            // Check for UID and create if it doesn't yet exist
            if (!("uid" in cks))
            {
                console.log("Setting UID cookie for first-time visitor");

                const uid = this.createUid();
                Cookies.set("uid", uid, { "expires": 10000 });
                Cookies.set("u0", uid);

                firstTimeVisitor = true;
            }
            else if ("u0" in cks)
            {
                Cookies.remove("u0");
            }

            // Check for "cvmtest" url param and set cookie if it exists
            if ("cvmtest" in Config.urlQueryParams)
            {
                this.setTestCookie();
            }
        }
        else
        {
            console.log("Cookies not enabled");
        }

        return firstTimeVisitor;
    }

    clearAllCookies()
    {
        console.log("Clearing all cookies...");

        Cookies.remove("s");
        Cookies.remove("sr");
        Cookies.remove("sl");
        Cookies.remove("so");
        Cookies.remove("uid");
        Cookies.remove("u0");
        Cookies.remove("cvmtest");
    }

    setTestCookie()
    {
        console.log("Setting test cookie...");
        Cookies.set("cvmtest", "1");
    }

    createUid()
    {
        function s4()
        {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
}




export const cookieManager = new CookieManager();



