


/*
 q: queryStr,
offset,
pageSize,
startTime: new Date().getTime(),
data: DUMMY_RESULT
 */

export class SearchResults
{
    constructor(resultsObj)
    {
        this.resultsObj = resultsObj;
        this.esResults = resultsObj.data;
    }

    get queryId() {
        return this.esResults.qid;
    }

    get queryTime() {
        return this.esResults.esTime;
    }

    get totalResults() {
        return this.esResults.hits.total;
    }

    get numberOfRetrievedResults() {
        return this.esResults.hits.hits.length;
    }

    get offset() {
        return this.resultsObj.offset;
    }

    get pageSize() {
        return this.resultsObj.pageSize;
    }

    getResults()
    {
        return this.esResults.hits.hits;
    }
}