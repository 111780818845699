


import { knowledgeHubUtils } from "../hub-utils";


export class VideoPlayer
{
    constructor(elemId, config)
    {
        this.videoElemId = elemId;
        this.videoElemSelector = "#" + this.videoElemId;
        this.config = config;
        this.videoId = this.config.videoId || window.location.href;
        this.maxProgress = 0;
        this.eventInterval = this.config.eventInterval || 30;
        this.lastStartEvent = 0;

        // Hack for IE10 compatibility
        this.ie10Mode = false;

        this.init();
    }


    init()
    {
        const self = this;

        const videoElement = document.getElementById(this.videoElemId);

        const playerConfig = {
            "controls": true,
            "autoplay": false,
            "preload": "metadata",
            "fluid" : "true"
        };

        if (this.config.poster)
        {
            playerConfig.poster = this.config.poster;
        }

        this.player = videojs(videoElement, playerConfig);

        // Source URL(s)
        const srcUrls = [ ];

        if (Array.isArray(this.config.sourceUrl))
        {
            for (let srcUrl of this.config.sourceUrl)
            {
                const videoMime = this.getMimeTypeForSource(srcUrl);
                srcUrls.push({
                    src: srcUrl,
                    type: videoMime
                });
            }
        }
        else
        {
            const videoUrl = this.config.sourceUrl;
            const videoMime = this.getMimeTypeForSource(videoUrl);

            srcUrls.push({
                src: videoUrl,
                type: videoMime
            });
        }

        this.player.src(srcUrls);

        this.player.on("play", function() {
            self.handlePlaybackStarted();
        });

        this.player.on("pause", function() {
            self.handlePlaybackPaused();
        })

        this.player.on("timeupdate", function() {
            self.handleTimeUpdate();
        });

        this.player.on("ended", function() {
            self.handlePlaybackEnded();
        });

        // IE 10 backwards compatibility
        this.initIE10();
    }

    initIE10()
    {
        const self = this;

        if (navigator.appVersion.indexOf("MSIE 10") !== -1)
        {
            this.ie10Mode = true;

            const ctrls = `<button id="vidPlayFbkwd" class="btn btn-primary" disabled="disabled" title="Back 30 Seconds"><i class="fa fa-fast-backward"></i></button>
 &nbsp;&nbsp;<button id="vidPlayBtn" class="btn btn-primary"><i class="fa fa-play"></i> <span>Play</span></button>
 &nbsp;&nbsp;<strong id="vidPlayProg" style="display: none;"><span id="vidPlayCurrTime">0:00</span> of <span id="vidPlayDur">0:00</span></strong>
 &nbsp;&nbsp;<button id="vidPlayFfwd" class="btn btn-primary" disabled="disabled" title="Forward 30 seconds"><i class="fa fa-fast-forward"></i></button>`;

            const ctrlsDiv = $("<div/>").css("margin-top", "20px").html(ctrls);

            $(this.videoElemSelector).after( $(ctrlsDiv) );

            $("#vidPlayBtn").click(function() {
                if (self.player.paused())
                {
                    self.player.play();
                }
                else
                {
                    self.player.pause();
                }

                self.ie10UpdateControls();
            });

            $("#vidPlayFbkwd").click(function() {
                self.fastBackward(30);
            });

            $("#vidPlayFfwd").click(function() {
                self.fastForward(30);
            });
        }
    }

    ie10UpdateControls()
    {
        if (this.player.paused())
        {
            $("#vidPlayBtn span").text("Play");
            $("#vidPlayBtn i").removeClass("fa-pause").addClass("fa-play");
            $("#vidPlayFbkwd").prop("disabled", true);
            $("#vidPlayFfwd").prop("disabled", true);
        }
        else
        {
            $("#vidPlayBtn span").text("Pause");
            $("#vidPlayBtn i").removeClass("fa-play").addClass("fa-pause");
            $("#vidPlayFbkwd").prop("disabled", false);
            $("#vidPlayFfwd").prop("disabled", false);

            $("#vidPlayProg").show();
            this.ie10UpdateTime();
        }
    }

    ie10UpdateTime()
    {
        $("#vidPlayCurrTime").text(this.formatTime(this.player.currentTime()));
        $("#vidPlayDur").text(this.formatTime(this.player.duration()));
    }

    formatTime(timeInSecs)
    {
        const mins = Math.floor(timeInSecs / 60);
        const secs = Math.floor(timeInSecs) % 60;

        return `${mins}:${secs >= 10 ? secs : "0" + secs}`;
    }

    fastBackward(amt)
    {
        let newTime = this.player.currentTime() - amt;
        if (newTime < 0)
        {
            newTime = 0;
        }

        this.player.currentTime(newTime);
    }

    fastForward(amt)
    {
        let newTime = this.player.currentTime() + amt;
        if (newTime >= this.player.duration())
        {
            newTime = this.player.duration() - 15;
        }

        this.player.currentTime(newTime);
    }

    handleTimeUpdate()
    {
        const time = this.player.currentTime();
        if (time - this.maxProgress > this.eventInterval)
        {
            const progress = Math.floor(time / this.eventInterval) * this.eventInterval;
            console.log(`Time update ${progress} sec`);

            knowledgeHubUtils.event("vid", [ "prog", this.videoId, progress ]);

            this.maxProgress = progress;
        }

        if (this.ie10Mode)
        {
            this.ie10UpdateTime();
        }
    }

    handlePlaybackStarted()
    {
        console.log("Video playback started");


        // Debounce the start events, because for some reason we often receive more than one (need to figure out why)
        const now = new Date().getTime();

        // Ignore unless the event happened more than 5 seconds from previous start
        if (now - this.lastStartEvent >= 5000)
        {
            this.lastStartEvent = now;

            // Reset current time, may not be 0 if the user paused or jumped ahead and then restarted
            this.maxProgress = this.player.currentTime();

            knowledgeHubUtils.event("vid", [ "prog", this.videoId, 0 ]);
        }

        // IE10 fix
        if (this.ie10Mode)
        {
            this.ie10UpdateControls();
        }
    }

    handlePlaybackPaused()
    {
        // IE10 update playback controls
        if (this.ie10Mode)
        {
            this.ie10UpdateControls();
        }
    }

    handlePlaybackEnded()
    {
        console.log("Video playback ended");

        knowledgeHubUtils.event("vid", [ "prog", this.videoId, 999999 ]);

        // IE10 fix
        if (this.ie10Mode)
        {
            this.ie10UpdateControls();
        }
    }

    getMimeTypeForSource(videoUrl)
    {
        if (videoUrl.endsWith(".mpd"))
        {
            return "application/dash+xml";
        }
        else // assume default of mp4
        {
            return "video/mp4";
        }
    }
}