


import { CitationFormatterBase } from "./CitationFormatterBase";


/**
 * For information on the Chicago citation format, see https://www.chicagomanualofstyle.org/tools_citationguide/citation-guide-1.html
 */
export class CitationFormatterChicago extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let result = "";

        let authorsStr = "";
        switch (authorsList.length)
        {
            case 0:
                break;

            case 1:
                authorsStr += authorsList[0].getFirstNameSurname();
                if (authorsList[0].props.role)
                {
                    authorsStr += (", " + this.getAbbreviatedRole(authorsList[0].props.role).toLowerCase());
                }
                break;

            case 2:
                authorsStr += authorsList[0].getFirstNameSurname();
                authorsStr += " and ";
                authorsStr += authorsList[1].getFirstNameSurname();
                if (authorsList[0].props.role)
                {
                    authorsStr += (", " + this.getAbbreviatedRolePlural(authorsList[0].props.role).toLowerCase());
                }
                break;

            case 3:
                authorsStr += authorsList[0].getFirstNameSurname();
                authorsStr += ", ";
                authorsStr += authorsList[1].getFirstNameSurname();
                authorsStr += " and ";
                authorsStr += authorsList[2].getFirstNameSurname();
                if (authorsList[0].props.role)
                {
                    authorsStr += (", " + this.getAbbreviatedRolePlural(authorsList[0].props.role).toLowerCase());
                }
                break;

            default:
                authorsStr += authorsList[0].getFirstNameSurname();
                authorsStr += ", et al";
                break;
        }
        if (authorsStr != "")
        {
            result += authorsStr;
        }

        //
        //  BODY
        //
        if (citationObj.pubType === "book")
        {
            // Period after author(s)
            if (!result.endsWith(".") && authorsList.length > 0)
            {
                result += ".";
            }

            // TITLE
            result += (" <i>" + citationObj.title + "</i>");

            // LOCATION
            result += " (";
            result += citationObj.publisher.loc;

            // PUBLISHER
            result += ": ";
            result += citationObj.publisher.name;

            // DATE
            result += ", ";
            result += citationObj.pubYear;
            result += ")";


            // POSITION (OPTIONAL)
            if (citationObj.pos)
            {
                result += ", ";
                result += citationObj.pos;
            }

            result += ".";
        }
        else // periodical
        {
            // Comma after authors
            if (authorsList.length > 0)
            {
                result += ", ";
            }

            // Article title
            if (citationObj.title)
            {
                result += `"${citationObj.title}," `;
            }

            // Journal title in Italics
            result += `<i>${citationObj.publishedIn.title}</i> `;

            // Volume, no. Issue
            result += `${citationObj.publishedIn.volume}, no. ${citationObj.publishedIn.issue} `;

            // Date
            result += `(${citationObj.publishedIn.date})`;

            // Page range
            if (citationObj.publishedIn.pages)
            {
                result += `: ${citationObj.publishedIn.pages}`;
            }

            result += ".";
        }



        return result;
    }
}