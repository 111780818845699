


import $ from "jquery";
import { } from "./infohub-setup";


export class ModalDialog
{
    constructor(dialogId, config)
    {
        this.dialogId = dialogId;
        this.config = config || { };

        this.dialogSelector = "#" + dialogId;
        this.dialogTitle = config.title || "";
        this.dialogBody = config.body || "";
        this.submitButtonLabel = config.submitButtonLabel || "Okay";
        this.submitHandler = config.submitHandler || function() { };
    }


    show()
    {
        console.log("modal.show()");

        if ($(this.dialogSelector).length === 0)
        {
            console.log(`Creating modal dialog ${this.dialogId}`);

            const modalHtml = `
<div class="modal fade" id="${this.dialogId}" tabindex="-1" role="dialog" aria-labelledby="${this.dialogTitle}" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">${this.dialogTitle}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>
      <div class="modal-body">
        ${this.dialogBody}
      </div>
      <div class="modal-footer">
        <button id="${this.dialogId}SubmitBtn" type="button" class="btn btn-primary">${this.submitButtonLabel}</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
      </div>
    </div>
  </div>
</div>
    `;

            $("body").append($.parseHTML(modalHtml));

            var self = this;
            $(`#${this.dialogId}SubmitBtn`).click(function() {
                console.log("Submit button clicked");
                $(self.dialogSelector).modal('hide');
                self.submitHandler();
            });
        }

        console.log(`Showing modal ${this.dialogId}`);
        $(this.dialogSelector).modal();
    }
}