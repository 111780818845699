


import $ from 'jquery';
import {Config} from "./infohub-setup";

//console.log("infohub-dom-utils.js entered...");


export const InfohubDomUtils = {

    createEntityRefSnippets: function(data, citationGenerator)
    {
        const elemsList = [];

        for (let i = 0; i < data.refs.length; ++i)
        {
            const ref = data.refs[i];

            const newDiv = $("<div/>", { class: "snippet" });

            newDiv.append('"');

            let prevElemWasSnippet = false;

            for (let snipElem = 0; snipElem < ref.content.length; ++snipElem)
            {
                const elem = ref.content[snipElem];
                if (typeof elem === "string")
                {
                    newDiv.append(elem);
                    prevElemWasSnippet = false;
                }
                else // ref object
                {
                    var refEntIid = elem.entityIid;
                    var url = elem.url;
                    var txt = elem.text;

                    if (prevElemWasSnippet)
                    {
                        newDiv.append(" ");
                    }
                    prevElemWasSnippet = true;


                    if (refEntIid === entityIid)
                    {
                        // self
                        newDiv.append( $("<span/>", { class: "self" }).text(txt) );
                    }
                    else
                    {
                        newDiv.append( $("<a/>", { href: url }).text(txt) );
                    }
                }
            }

            newDiv.append('"');

            // Add a Read link if docpos is available
            if (ref.properties.docPosition)
            {
                newDiv.append(" (");
                newDiv.append( $("<a/>", { href: "#" } )
                                   .click(function() {
                                       readPdf(ref.properties.externalLink, ref.properties.pubIid, parseInt(ref.properties.docPosition) + 1);
                                       return false;
                                   })
                                   .text("p" + (parseInt(ref.properties.docPosition) + 1)) );
                newDiv.append(" ");
                newDiv.append( $("<i/>", { class: "fa fa-external-link", "aria-hidden": "true" } ).text(" "));
                newDiv.append(")");
            }

            // Citation Button
            newDiv.append(" ");
            const linkProps = { href: "#", title: "Cite This", class: "img-link citation-btn", "data-citation-type": "ref" };
            if (ref.properties.docPosition)
            {
                linkProps["data-citation-pos"] = parseInt(ref.properties.docPosition);
            }
            newDiv.append( $("<a/>", linkProps ).click(function() { citationGenerator.handleClick(this); return false; }).append( $("<img/>", { src: Config.resourceUrl("img/add-citation.svg") } )) );


            elemsList.push(newDiv);
        }

        return elemsList;
    }
};


