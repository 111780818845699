

import $ from 'jquery';


console.log("progressiveloader.js entered...");



export class ProgressiveLoader {

    /**
     *
     * @param loaderId
     * @param configParams
     */
    constructor(loaderId, configParams) {
        this.loaderId = loaderId;
        this.populateConfig(configParams || {});
        this.init();
    }

    get totalItems() { return this._totalItems; }
    get itemsPerBlock() { return this._itemsPerBlock; }
    get totalBlocks() { return this._totalBlocks; }
    get itemsLoaded() { return this._itemsLoaded; }


    populateNewElements(data)
    {
        // Append new elements
        const createResults = this.createNewElems(data);

        const newElems = (Array.isArray(createResults) ? createResults : createResults.elems);

        for (let i = 0; i < newElems.length; ++i)
        {
            this.$moreBtn.before(newElems[i]);
        }

        // Update items loaded
        this._itemsLoaded += newElems.length;

        // Hide the MORE button if there's nothing else to load
        if (this.itemsLoaded >= this.totalItems)
        {
            this.$moreBtn.hide();
        }

        // If the createResults included a postAdd callback, invoke it
        if (!Array.isArray(createResults) && createResults.postAdd)
        {
            createResults.postAdd(this);
        }
    }


    initiateBlockDataLoad(blockOffset) {
        const self = this;

        const successCallback = function(data) {
            console.log(`Completed load of data for block ${blockOffset}`);

            // Update the UI
            self.populateNewElements(data);

            // Load finished
            self.loadInProgress = false;
        };

        const errorCallback = function(errInfo) {
            console.log(`Failed load of data for block ${blockOffset}`);
            self.loadInProgress = false;
        };

        // Mark load in progress
        this.loadInProgress = true;

        // Block loader - function(blockOffset, successCallback(data), errorCallback(errInfo) )
        this.blockDataLoader(blockOffset, successCallback, errorCallback, this);
    }

    init() {
        const self = this;

        this.$loader.addClass("progressive-loader");

        // Add the loader to the end of the div, if there are more items to load
        if (this.itemsLoaded < this._totalItems)
        {
            const moreBtnId = `${this.loaderId}_morebtn`;
            if (this.showMoreButton)
            {
                this.$moreBtn = $("<div/>", { id: moreBtnId, class: "moreBtn" }).text("More").click(function() {
                    self.handleMoreButtonPressed(this);
                });
            }
            else 
            {
                this.$moreBtn = $("<div/>");
            }

            this.$loader.append(this.$moreBtn);
        }

        // If no elements have been loaded and autoLoad is enabled, initiate a load
        if (this.itemsLoaded === 0 && this.autoLoadIfEmpty)
        {
            console.log("Autoloading block 0");
            this.initiateBlockDataLoad(0);
        }
    }

    handleMoreButtonPressed(btnElem) {
        console.log("MORE button pressed");
        this.loadMore();
    }

    loadMore()
    {
        console.log("Loading more");
        if (!this.loadInProgress)
        {
            this.initiateBlockDataLoad(this.itemsLoaded);
        }
    }

    reset()
    {
        console.log("Resetting progressive loader...");
        this._itemsLoaded = 0;
        this.$loader.empty();
        this.init();
    }

    populateConfig(configParams)
    {
        // Block loader - function(blockNum, successCallback(data), errorCallback(errInfo) )

        this.blockDataLoader = configParams.blockDataLoader;

        this.createNewElems = configParams.createNewElems;


        // Setup Elems
        this.$loader = $("#" + this.loaderId);

        this.autoLoadIfEmpty = this.initConfigParamBool(configParams, "autoLoad", "auto-load", "false");
        this.showMoreButton = this.initConfigParamBool(configParams, "showMoreButton", "show-more-button", "true");

        // Item config
        this.blocksPreloaded = this.initConfigParamNum(configParams, "blocksPreloaded", "blocks-preloaded", 1);
        this._totalItems = this.initConfigParamNum(configParams, "totalItems", "total-items");                  // config.totalItems;
        this._itemsPerBlock = this.initConfigParamNum(configParams, "itemsPerBlock", "items-per-block");        // config.itemsPerBlock;

        // Computed items config
        this._totalBlocks = Math.ceil(this.totalItems / this.itemsPerBlock);
        this._itemsLoaded = this.blocksPreloaded * this._itemsPerBlock;

        // Initial state values
        this.loadInProgress = false;
    }

    initConfigParam(configParams, name, dataName, defaultVal)
    {
        // Look in "data-{dataName}" param first
        const attrVal = this.$loader.attr("data-" + dataName);
        if (typeof attrVal != "undefined")
        {
            return attrVal;
        }

        // Look in configParams, if avalable
        if (configParams)
        {
            const configVal = configParams[name];
            if (typeof configVal != "undefined")
            {
                return configVal;
            }
        }

        // Else default val, if present
        if (typeof defaultVal != "undefined")
        {
            return defaultVal;
        }
        else
        {
            throw "Missing config value: " + name;
        }
    }

    initConfigParamNum(configParams, name, dataName, defaultVal)
    {
        return parseInt(this.initConfigParam(configParams, name, dataName, defaultVal));
    }

    initConfigParamBool(configParams, name, dataName, defaultVal)
    {
        const val = this.initConfigParam(configParams, name, dataName, defaultVal);
        if (typeof val === "string" && val.length > 0)
        {
            switch (val.charAt(0))
            {
                case "T":
                case "t":
                case "1":
                    return true;

                default:
                    return false;
            }
        }
        else
        {
            return Boolean(val);
        }
    }

}