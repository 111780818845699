


import { CitationFormatterBase } from "./CitationFormatterBase";


/**
 * For information on the APA citation format, see http://www.easybib.com/guides/citation-guides/apa-format/
 *
 */
export class CitationFormatterApa extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let result = "";

        // AUTHORS
        let authorsStr = "";
        switch (authorsList.length)
        {
            case 0:
                // No authors -> use Anonymous
                authorsStr += "Anonymous";
                break;

            case 1:
                authorsStr = authorsList[0].getSurnameCommaFirstAndMiddleInitial();
                if (authorsList[0].props.role)
                {
                    authorsStr += (" (" + this.getAbbreviatedRole(authorsList[0].props.role) + ")");
                }
                break;

            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
                for (let i = 0; i < authorsList.length; ++i)
                {
                    if (i > 0)
                    {
                        authorsStr += ", ";
                    }
                    if (i == authorsList.length - 1)
                    {
                        authorsStr += "&amp; ";
                    }

                    authorsStr += authorsList[i].getSurnameCommaFirstAndMiddleInitial();
                }
                break;

            default: // 8 or more
                // show first 6
                for (let i = 0; i < 6; ++i)
                {
                    if (i > 0)
                    {
                        authorsStr += ", ";
                    }

                    authorsStr += authorsList[i].getSurnameCommaFirstAndMiddleInitial();
                }
                // Add ",..." + last
                authorsStr += ",... ";
                authorsStr = authorsList[authorsList.length - 1].getSurnameCommaFirstAndMiddleInitial();
                break;
        }

        if (authorsStr != "")
        {
            result += authorsStr;
            if (!result.endsWith("."))
            {
                result += ".";
            }
        }

        // YEAR PUBLISHED
        result += (" (" + citationObj.pubYear + "). ");

        //
        //  BODY (different for books and periodicals)
        //
        if (citationObj.pubType === "book")
        {
            result += ("<i>" + citationObj.title + "</i>. ");

            // PUBLISHER LOCATION
            result += citationObj.publisher.loc;
            result += ": ";

            // PUBLISHER
            result += citationObj.publisher.name;
            result += ".";
        }
        else // periodical
        {
            // Article title (title may not be present in issue citations, for example)
            if (citationObj.title)
            {
                result += citationObj.title;
                result += ". ";
            }

            // "Journal title" in italics
            result += `<i>${citationObj.publishedIn.title}</i>, `;

            // Volume in italics
            result += `<i>${citationObj.publishedIn.volume}</i>(${citationObj.publishedIn.issue})`;

            // Pages
            if (citationObj.publishedIn.pages)
            {
                result += `, ${citationObj.publishedIn.pages}`;
            }

            result += ".";
        }


        return result;
    }
}