

import { CitationFormatterBase } from "./CitationFormatterBase";


export class CitationFormatterAupNote extends CitationFormatterBase
{
    constructor()
    {
        super();
    }

    generateCitation(citationObj, config)
    {
        let result = "";

        //
        //  AUTHOR
        //
        result += this.generateAuthorString(citationObj, config);

        //
        // CITATION BODY
        //
        if (citationObj.pubType === "book")
        {
            result += `<i>${citationObj.title}</i> `;

            result += `(${citationObj.publisher.loc}: ${citationObj.publisher.name}, ${citationObj.pubYear})`;

            // Optional page range
            if (citationObj.pos)
            {
                result += `, ${citationObj.pos}`;
            }

            result += ".";
        }
        else // periodical
        {
            // Article title
            if (citationObj.title)
            {
                result += `"${citationObj.title}," `;
            }

            // Periodical title in italics
            result += `<i>${citationObj.publishedIn.title}</i> `;

            // Volume number, Issue number, and Date
            let pageRangeSeparator = "";

            if (citationObj.publishedIn.volume && citationObj.publishedIn.volume !== "")
            {
                result += `${citationObj.publishedIn.volume}, no. ${citationObj.publishedIn.issue} (${citationObj.publishedIn.date})`;
                pageRangeSeparator = ":";
            }
            else // no volume, so use date only
            {
                result += "," + citationObj.publishedIn.date;
                pageRangeSeparator = ",";
            }

            // Page range
            if (citationObj.publishedIn.pages)
            {
                result += `${pageRangeSeparator} ${citationObj.publishedIn.pages}`;
            }


            result += ".";
        }

        return result;
    }


    generateAuthorString(citationObj, config)
    {
        const authorsList = citationObj.authors;

        let authorStr = "";

        switch (authorsList.length)
        {
            case 0:
                break;

            case 1:
                authorStr = authorsList[0].getFirstNameMiddleInitialSurnameWithPrefix();
                break;

            case 2:
                authorStr = `${authorsList[0].getFirstNameMiddleInitialSurnameWithPrefix()} and ${authorsList[1].getFirstNameMiddleInitialSurnameWithPrefix()}`;
                break;

            case 3:
                authorStr = `${authorsList[0].getFirstNameMiddleInitialSurnameWithPrefix()}, ${authorsList[1].getFirstNameMiddleInitialSurnameWithPrefix()}, and ${authorsList[2].getFirstNameMiddleInitialSurnameWithPrefix()}`;
                break;

            default:
                authorStr = `${authorsList[0].getFirstNameMiddleInitialSurnameWithPrefix()} et al.`;
                break;
        }

        // Add role
        if (authorsList.length > 0 && authorsList[0].props.role)
        {
            const abbrevRole = (authorsList.length == 1 ? this.getAbbreviatedRole(authorsList[0].props.role) : this.getAbbreviatedRolePlural(authorsList[0].props.role));
            authorStr += ", ";
            authorStr += abbrevRole.toLowerCase();
        }

        if (authorsList.length > 0)
        {
            authorStr += ", ";
        }

        return authorStr;
    }
}