

import { Config } from "../infohub-setup";
import { SearchResults } from "./SearchResults";


const METHOD_NAME = "search";


export class SearchApiEndpoint
{
    constructor()
    {

    }

    fulltextQuery(queryStr, config)
    {
        console.log(`Loading fulltext query results for '${queryStr}'`);

        /*
        GET with the following URL parameters:
          q = queryStr
          t = type ("title", "fulltext")
          from = start offset, zero based
          size = page size
         */

        return Config.promiseExtendedConfig()
                     .then(extConfig => this.internalDoQuery(queryStr, config, extConfig));

    }

    async internalDoQuery(queryStr, config, externalConfig)
    {
        const pageSize = config.pageSize;
        const offset = config.offset;
        const startTime = new Date().getTime();

        const apiEndpointUrl = externalConfig.khubApi.endpointUrl;
        const url = `${apiEndpointUrl}/${METHOD_NAME}`;

        const queryObj = {
            q: queryStr,
            t: "fulltext",
            from: offset,
            size: pageSize
        };

        if (config.qid)
        {
            queryObj.qid = config.qid;
        }

        return $.ajax({
            url: url,
            type: "GET",
            crossDomain: true,
            //contentType: "application/json",
            dataType: "json",
            data: queryObj
        }).then(result => new SearchResults({
            q: queryStr,
            offset,
            pageSize,
            startTime,
            data: result
        }));

        /*
        return Promise.resolve(new SearchResults({
            q: queryStr,
            offset,
            pageSize,
            startTime: new Date().getTime(),
            data: DUMMY_RESULT
        }));
        */
    }
}

const DUMMY_RESULT = {
    "took": 230,
    "timed_out": false,
    "_shards": {
        "total": 5,
        "successful": 5,
        "skipped": 0,
        "failed": 0
    },
    "hits": {
        "total": 16,
        "max_score": 94.14778,
        "hits": [
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_space_power",
                "_score": 94.14778,
                "_source": {
                    "entType": [
                        "Concept"
                    ],
                    "khubId": "space_power",
                    "khubUrl": "entity/space_power",
                    "iid": "2_54145",
                    "name": "Space Power",
                    "totalRefs": 13,
                    "numOfRefingPubs": 3
                },
                "highlight": {
                    "name": [
                        "<em>Space</em> Power"
                    ],
                    "label": [
                        "<em>Space</em> Power"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_space_superiority",
                "_score": 90.29164,
                "_source": {
                    "entType": [
                        "Concept"
                    ],
                    "khubId": "space_superiority",
                    "khubUrl": "entity/space_superiority",
                    "iid": "2_63992",
                    "name": "Space Superiority",
                    "totalRefs": 15,
                    "numOfRefingPubs": 5
                },
                "highlight": {
                    "name": [
                        "<em>Space</em> Superiority"
                    ],
                    "label": [
                        "<em>Space</em> Superiority"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_air_and_space_power_journal",
                "_score": 81.14345,
                "_source": {
                    "entType": [
                        "Concept"
                    ],
                    "khubId": "air_and_space_power_journal",
                    "khubUrl": "entity/air_and_space_power_journal",
                    "iid": "2_54737",
                    "name": "Air and Space Power Journal",
                    "totalRefs": 0,
                    "numOfRefingPubs": 0
                },
                "highlight": {
                    "name": [
                        "Air and <em>Space</em> Power Journal"
                    ],
                    "label": [
                        "Air and <em>Space</em> Power Journal",
                        "Air & <em>Space</em> Power Journal",
                        "Air and <em>Space</em> Power Journal (ASPJ)",
                        "Air and <em>Space</em> Power Journal [ASPJ]",
                        "Air & <em>Space</em> Power Journal (ASPJ)"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_cyberspace",
                "_score": 78.45038,
                "_source": {
                    "entType": [
                        "Concept"
                    ],
                    "khubId": "cyberspace",
                    "khubUrl": "entity/cyberspace",
                    "iid": "2_49155",
                    "name": "Cyber Space",
                    "totalRefs": 7,
                    "numOfRefingPubs": 1
                },
                "highlight": {
                    "name": [
                        "Cyber <em>Space</em>"
                    ],
                    "label": [
                        "Cyber <em>Space</em>"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_spacy_bradley_d",
                "_score": 71.797455,
                "_source": {
                    "entType": [
                        "Person"
                    ],
                    "khubId": "spacy_bradley_d",
                    "khubUrl": "entity/spacy_bradley_d",
                    "iid": "2_39372",
                    "name": "Bradley D. Spacy",
                    "totalRefs": 0,
                    "numOfRefingPubs": 0
                },
                "highlight": {
                    "name": [
                        "Bradley D. <em>Spacy</em>"
                    ],
                    "label": [
                        "Bradley D. <em>Spacy</em>",
                        "Bradley <em>Spacy</em>",
                        "B. D. <em>Spacy</em>",
                        "<em>Spacy</em>",
                        "<em>Spacy</em>, Bradley"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_usafacsc_space_research_elective_seminar",
                "_score": 42.755157,
                "_source": {
                    "entType": [],
                    "khubId": "usafacsc_space_research_elective_seminar",
                    "khubUrl": "entity/usafacsc_space_research_elective_seminar",
                    "iid": "2_46182",
                    "name": "United States Air Force Air Command and Staff College Space Research Electives Seminars",
                    "totalRefs": 27,
                    "numOfRefingPubs": 12
                },
                "highlight": {
                    "name": [
                        "United States Air Force Air Command and Staff College <em>Space</em> Research Electives Seminars"
                    ],
                    "label": [
                        "United States Air Force Air Command and Staff College <em>Space</em> Research Electives Seminars",
                        "Air Command and Staff College <em>Space</em> Research Electives Seminars"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_china_in_space",
                "_score": 37.472412,
                "_source": {
                    "entType": [
                        "WORK_TYPE_WORK",
                        "WORK_TYPE_ARTICLE"
                    ],
                    "khubId": "china_in_space",
                    "khubUrl": "pub/china_in_space/home",
                    "iid": "2_56218",
                    "name": "China in Space: Ambitions and Possible Conflict",
                    "imageIid": "2_56280",
                    "totalRefs": 13,
                    "numOfRefingPubs": 3
                },
                "highlight": {
                    "name": [
                        "China in <em>Space</em>: Ambitions and Possible Conflict"
                    ],
                    "fulltext": [
                        "This includes bringing back samples from Mars for research as well as asteroid exploration. 54  Below is a detailed analysis of these three unique <em>space</em> goals. <em>Space</em>-Based Solar Power China's <em>space</em> solar ambitions were outlined in a 2010 report by its leading <em>space</em> agency, CAST. The report stated, \"In 2010, CAST will finish the concept design; in 2020, we will finish the industrial level testing of in-orbit construction and wireless transmissions. ",
                        "It remains to be seen whether the shift in China's <em>space</em> goals, as articulated by its scientists and <em>space</em> policy makers, to acquire <em>space</em>-based resources and a permanent <em>space</em> station lead to resource nationalism, territoriality, and expansionism. ",
                        `C h in a in S p a c e : A m b itio n s a n d P o s s ib le C o n fl ic t
Nam rata Goswam i
To explore the vast cosmos, develop the <em>space</em> industry and build China into a <em>space</em> power is a dream we pursue unremittingly.
--"China's <em>Space</em> Activities in 2016"
Abstract
Major powers like China are viewing space less concerned with "securing the high ground" for espionage and nuclear deterrence and more for access to the vast material and energy resources of the inner solar system.
""",
"China aims to establish a manned <em>space</em> station by 2020–22 and a <em>space</em>-based solar power station by 2050 to meet its burgeoning economic and energy needs, develop <em>space</em> science and technology, explore outer space, and land on Mars. ",
    """
It identifies strains of nationalism and internationalism and specifically discusses Chinese policy attitudes and aspirations related to <em>space</em>-based solar power (SBSP), lunar and asteroid mining, <em>space</em> settlement, and planetary defense. 1
✵ ✵ ✵ ✵ ✵
Our contemporary <em>space</em> age is moving toward the prospect of harvesting space-based resources for long-term national economic development.`
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_2_1921",
                "_score": 35.834328,
                "_source": {
                    "entType": [],
                    "khubId": "2_1921",
                    "khubUrl": "entity/2_1921",
                    "iid": "2_1921",
                    "name": "AUP Category Air and Space Power",
                    "totalRefs": 13,
                    "numOfRefingPubs": 3
                },
                "highlight": {
                    "name": [
                        "AUP Category Air and <em>Space</em> Power"
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_space_arms_control",
                "_score": 35.278625,
                "_source": {
                    "entType": [
                        "WORK_TYPE_WORK",
                        "WORK_TYPE_ARTICLE"
                    ],
                    "khubId": "space_arms_control",
                    "khubUrl": "pub/space_arms_control/home",
                    "iid": "2_55907",
                    "name": "Space Arms Control: A Hybrid Approach",
                    "imageIid": "2_55969",
                    "totalRefs": 13,
                    "numOfRefingPubs": 3
                },
                "highlight": {
                    "name": [
                        "<em>Space</em> Arms Control: A Hybrid Approach"
                    ],
                    "fulltext": [
                        `Further, the committee approved the draft resolution on the Prevention of An Arms Race in Outer Space by a recorded vote of 180 in favor to none against, with 2 abstentions (United States and Israel). 32  By proposing and actively pursuing practical space arms control, the United States can regain leadership and worldwide support to ensure beneficial space activities without the dangerous side effects of <em>space</em> weapons.
    The current US national <em>space</em> strategy cannot deal with the <em>space</em> stalker threat. 33  However, a new <em>space</em> arms control proposal can deter and defend against space stalkers, while the United States and other countries continue to use their existing and developing strategies and assets to deal with traditional threats such as ground-launched ASATs as well as other new threats such as cyberattack.`,
                        `At the same time, the United States should understand that "the possession, testing, production, and stockpiling" of some weapons, such as space stalkers, does not lead to "rapidly breaking out of" the hybrid treaty since it is not broken (rather alerted) by the rapidly increased number of <em>space</em> stalkers present in <em>space</em>, but by <em>space</em> stalkers being too close to US satellites. Finally, compared to the current state of no <em>space</em> arms control, a hybrid approach that restricts placement locations would be far better.`,
                        "These proposals will not work in the emerging <em>space</em> proximity-operations era. This article proposes a hybrid approach to <em>space</em> arms control based on restricting the locations in <em>space</em> of some potential space weapons while banning other types of space weapons outright. ",
                        "This article proposes a hybrid approach to space arms control based on restricting the locations in space of some potential <em>space</em> weapons while banning other types of <em>space</em> weapons outright. The core of any hybrid <em>space</em> arms control (HSAC) treaty should prohibit satellites, whether for antisatel- lite (ASAT) or peaceful purposes, from positioning too close to more than an innocuous threshold number of another country's satellites and authorize preemptive self-defense as a last resort countermeasure. ",
                        `Today, more arms control measures should be implemented to further improve effectiveness and affordability in dealing not only with <em>space</em> stalkers, but other emerging <em>space</em> weapons as well. Without successful arms control, our continued "peaceful uses of outer <em>space</em>" will be in jeopardy.`
                    ]
                }
            },
            {
                "_index": "testkbsearch",
                "_type": "_doc",
                "_id": "entity_usaf_saas",
                "_score": 13.1770525,
                "_source": {
                    "entType": [],
                    "khubId": "usaf_saas",
                    "khubUrl": "entity/usaf_saas",
                    "iid": "2_47376",
                    "name": "United States Air Force School of Advanced Airpower Studies",
                    "totalRefs": 13,
                    "numOfRefingPubs": 3
                },
                "highlight": {
                    "label": [
                        "School of Advanced Air and <em>Space</em> Studies",
                        "School of Advanced Air and <em>Space</em> Studies (SAAS)"
                    ]
                }
            }
        ]
    }
};