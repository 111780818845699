

import $ from "jquery";

import { CitationGenerator } from "./CitationGenerator";


export class CitationManager
{
    constructor(config)
    {
        this.citationGenerator = new CitationGenerator();

        this.formats = [ "aup", "aupShort", "aupBiblio", "apa", "chicago", "mla" ];

        this.populateConfig(config);
        this.init();
    }

    handleClick(clickedElem)
    {
        console.log(`Citation button clicked`);

        const citationObj = this.citationSource($(clickedElem));

        console.log(`Got citation object ${JSON.stringify(citationObj)}`);

        this.showCitationDialog(citationObj);

        return false;
    }

    prepareCitationDialog(citationObj, dialogElem)
    {
        const cits = this.citationGenerator.generateCitation(citationObj, this.formats, { });

        for (let fmt of this.formats)
        {
            const citElem = $(dialogElem).find(`.${fmt}Cit`);
            if (citElem && cits[fmt])
            {
                $(citElem).html(cits[fmt]);
            }
        }
    }

    showCitationDialog(citationObj)
    {
        let dialogElem = $("#" + this.dialogId);

        if ($(dialogElem).length === 0)
        {
            dialogElem = $("<div/>", { id: this.dialogId, class: "modal fade", tabIndex: "-1", role: "dialog", "aria-hidden": "true" });

            const body = `<div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Citation</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span area-hidden="true">&#215;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p><strong>AU Note Citation</strong><br/><span class="aupCit">AU Citation here...</span></p>
                        <p><strong>AU Note Citation (short)</strong><br/><span class="aupShortCit">AU Note Citation (short) here...</span></p>
                        <p><strong>AU Bibliographic Citation</strong><br/><span class="aupBiblioCit">AU Bibliographic Citation here...</span></p>
                        <p><strong>MLA</strong><br/><span class="mlaCit">MLA Citation here...</span></p>
                        <p><strong>APA</strong><br/><span class="apaCit">APA Citation here...</span></p>
                        <p><strong>CHICAGO</strong><br/><span class="chicagoCit">Chicago Citation here...</span></p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>`;

            $(dialogElem).html(body).appendTo($("body"));
        }

        this.prepareCitationDialog(citationObj, dialogElem);

        $(dialogElem).modal();
    }


    init()
    {
        const self = this;

        console.log("Initializing Citation Generator...");

        $(this.citationBtnSelector).click(function() {
            return self.handleClick($(this));
        });
    }

    populateConfig(config)
    {
        this.citationBtnSelector = ".citation-btn";
        this.dialogId = "citationDialogDiv";

        this.citationSource = config.citationSource;
    }
}